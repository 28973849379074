import { Button, Empty, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContextUsers } from '../../../../../context/users';
import { usePlanId, useSubscriptionId } from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import { formatPlanData } from '../../../../Common/PlansDetails/PlanItem';

import styles from './index.module.less';

const CurrentPlan = () => {
  const navigate = useNavigate();

  const { profile } = useContextUsers();
  const planId = usePlanId();
  const subscriptionId = useSubscriptionId();
  const [trialDaysLeft, setTrialDaysLeft] = useState<number>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    if (profile?.planId) {
      planId.fetch(undefined, profile.planId);
    }

    if (profile?.subscriptionId) {
      subscriptionId.fetch(undefined, profile.subscriptionId).then((response) => {
        if (!response?.data) return;

        const { current_period_start, current_period_end, plan } = response.data;

        if (plan?.trial_period_days) {
          setEndDate(moment.unix(current_period_start).add(plan?.trial_period_days, 'days').format('MMM DD, YYYY'));
        } else {
          setEndDate(moment.unix(current_period_end).format('MMM DD, YYYY'));
        }

        if (plan?.trial_period_days) {
          setTrialDaysLeft(moment.unix(current_period_start).add(plan.trial_period_days, 'days').diff(moment(), 'd'));
        }
      });
    }
  }, [profile]);

  const handleUpdatePlan = () => {
    navigate('/plans');
  };

  return (
    <ContentCard
      loading={planId.loading || subscriptionId.loading}
      bordered
      padding={20}
      height="100%"
      footer={
        <Button type="default" className="btn-default-dark-blue" size="large" onClick={handleUpdatePlan}>
          {profile?.planId ? 'Upgrade plan' : 'Select plan'}
        </Button>
      }
    >
      {profile?.planId ? (
        <div className={styles.content}>
          <div className={styles.textRow}>
            <div>
              <Typography.Title level={3}>
                {planId.data?.data?.nickname} {trialDaysLeft !== undefined && `(${trialDaysLeft} days left)`}
              </Typography.Title>
              {endDate && <Typography className="color-gray">Next renewal on {endDate}</Typography>}
            </div>
            {planId.data?.data.amount && planId.data.data.interval && (
              <Typography.Title className={styles.price}>
               {formatPlanData(planId.data.data)}
              </Typography.Title>
            )}
          </div>
        </div>
      ) : (
        <Empty
          style={{ margin: 0, flex: '1 1 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No active plan"
        />
      )}
    </ContentCard>
  );
};

export default CurrentPlan;
