import { Col, Form, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import { IReportLoan, useReportBreakEvenPoint } from '../../../../../hooks/reports';
import ContentCard from '../../../../Common/ContentCard';
import CustomInput from '../../../../Common/CustomInput';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import MovingTo from '../../../../Common/MovingTo';
import { commafy } from '../../../../../utils/text';

interface IBreakEventPoint {
  provided?: DraggableProvided;
}

const BreakEventPoint = ({ provided }: IBreakEventPoint) => {
  const name = 'breakEvenPoint';
  const { loans, isBorrower, handleLoansUpdate } = useContextReports();
  const form = Form.useFormInstance();
  const reportBreakEvenPoint = useReportBreakEvenPoint();
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const movingTo = useWatch(['movingTo', name], form);
  const [key, setKey] = useState(0)
  const index = loans ? loans.findIndex((loan: IReportLoan) => loan.id === movingTo) : 0;
  const [interestRate, setInterestRate] = useState<string>('0')
  const [loanPoint, setLoanPoint] = useState<string>('0')
  const delay = 1000;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!loans || !isSectionActive || !loans?.[key]) return;

      const loanIndex = loans.findIndex((item: IReportLoan) => item.id === movingTo);

      if (loanIndex >= 0) {
        setKey(loanIndex);
      }

      const { interestRateWithPoint, interestRateWithoutPoints, totalLoanPoints, loanPoints } = loans[key];

      setInterestRate(interestRateWithPoint)
      setLoanPoint(totalLoanPoints === '0' ? loanPoints : totalLoanPoints)

      const data = {
        interestRateWithPoint: interestRateWithPoint || null, interestRateWithoutPoints,
        totalLoanPoints: totalLoanPoints || loanPoints
      };

      reportBreakEvenPoint.fetch({ id: loans?.[key].id, ...data });
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);

  }, [loans, movingTo, isSectionActive, index]);

  return (
    <ReportSection
      id={name}
      name={name}
      title="Break-Even on Paying Loan Points"
      description="This section shows how long you would need to keep your mortgage in order to 
      break even on paying loan points. If you plan to sell your property or refinance 
      before the break-even point, you may want to reconsider paying points to buy down
      your interest rate."
      provided={provided}
    >
      <Space style={{ padding: "10px" }} direction="vertical" size={30}>
        <Row>
          <Col xs={24} sm={12} md={8} lg={8} >
            <TextWithLabel
              label="Break even Point"
              value={`${reportBreakEvenPoint.data?.data.years || 0} years,
               ${reportBreakEvenPoint.data?.data.months || 0} months`}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <TextWithLabel
              label="Monthly savings"
              value={`$${commafy(reportBreakEvenPoint.data?.data.monthlySavings) || 0}`}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} >
            <MovingTo name={name} />
          </Col>
        </Row>
        <ContentCard loading={!reportBreakEvenPoint.data && reportBreakEvenPoint.loading}>
          <Row gutter={[20, 20]}>
            <Col span={24} lg={5}>
              <CustomInput
                formItemProps={{
                  className: 'm-0 label-gray',
                  label: 'Interest Rate Without Points',
                  name: ['loans', key, 'interestRateWithoutPoints'],
                }}
                identifier={['loans', key, 'interestRateWithoutPoints']}
                suffix="%"
                placeholder="0%"
                bordered={!isBorrower}
                disabled={isBorrower}
                onBlur={handleLoansUpdate}
              />
            </Col>
            <Col span={24} lg={5}>
              <CustomInput
                formItemProps={{
                  className: 'm-0 label-gray',
                  label: 'Interest Rate With Points',
                  name: ['loans', key, 'interestRateWithPoint'],
                }}
                identifier={['loans', key, 'interestRateWithPoint']}
                bordered={!isBorrower}
                disabled={isBorrower}
                value={interestRate}
                placeholder="0%"
                suffix="%"
                onBlur={handleLoansUpdate}
              />
            </Col>
            <Col span={24} lg={5}>
              <CustomInput
                formItemProps={{
                  className: 'm-0 label-gray',
                  label: 'Total Cost of Loan Points',
                  name: ['loans', key, 'totalLoanPoints'],
                }}
                identifier={['loans', key, 'totalLoanPoints']}
                bordered={!isBorrower}
                disabled={isBorrower}
                placeholder="$0"
                prefix="$"
                value={loanPoint}
                onBlur={handleLoansUpdate}
              />
            </Col>
          </Row>
        </ContentCard>
      </Space>
    </ReportSection>
  );
};

BreakEventPoint.defaultProps = {
  provided: undefined,
};

export default BreakEventPoint;
