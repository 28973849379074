import axios, { AxiosError } from 'axios';
import { createContext, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { JsonResult } from '../types';
import { DefaultFetchError, getMessageInError, IDataErrors } from '../hooks/fetch';
import { getDataInStorage, setDataInStorage } from '../utils/storage';

interface UserData {
  id: string;
  email: string;
  lastName?: string;
  firstName?: string;
  phoneNumber?: string;
  avatar?: string;
  companyName?: string;
  websiteLink?: string;
  nmls?: string;
  address?: string;
  logo?: string;
  videoUrl?: string;
  planId?: string;
  customerId?: string;
  balance?: number;
  type?: string;
  permissions?:string;
}

export interface SignInData extends UserData {
  token: string;
  refreshToken: string;
}

export interface IUserResponse extends UserData {
  success: boolean;
  data: {
    user: UserData;
    token: string;
    hasPlan: boolean;
  };
}

export interface SignIn {
  email: string;
  password: string;
}

export interface ForgotPassword {
  email: string;
}

export interface ResetPassword {
  password: string;
  forgotPasswordCode: string;
}

export interface SignUp {
  email: string;
  password: string;
  nmlsId: number;
  ref?: string;
}

class Auth {
  private id: string | undefined;

  private email: string | undefined;

  private userData: UserData;

  private token: string | undefined;

  private refreshToken: string | undefined;

  private errorResponse: AxiosError<IDataErrors> | undefined;

  private signInLoading = false;

  private roleLoading = false;

  private hasPlan = false;

  private reLoginPromise?: Promise<SignInData | AxiosError<DefaultFetchError>>;

  constructor({ token, refreshToken, email, user }: JsonResult) {
    if (token && typeof token === 'string') this.token = token;
    if (refreshToken && typeof refreshToken === 'string') this.refreshToken = refreshToken;
    if (email && typeof email === 'string') this.email = email;
    this.userData = user || {
      id: undefined,
      email: undefined,
      customer: undefined,
      avatar: undefined,
      lastName: undefined,
      firstName: undefined,
      token: undefined,
      refreshToken: undefined,
      type: undefined
    };
  }

  private setData(data: IUserResponse) {
    if (data && data.data.token) {
      this.token = data.data.token;
      this.refreshToken = data.data.token;
      this.hasPlan = data.data.hasPlan;

      this.userData = {
        id: data.data.user.id,
        email: data.data.user.email,
        customerId: data.data.user.customerId,
        avatar: data.data.user.avatar,
        lastName: data.data.user.lastName,
        firstName: data.data.user.firstName,
        type: data.data.user.type
      };
    } else {
      this.clear();
    }
  }

  private clear() {
    this.id = undefined;
    this.email = undefined;
    this.token = undefined;
    this.refreshToken = undefined;
    this.hasPlan = false;

    setDataInStorage('auth', {});
  }

  private saveInStore() {
    setDataInStorage('auth', {
      user: this.userData,
      email: this.email,
      token: this.token,
      refreshToken: this.refreshToken,
      hasPlan: this.hasPlan,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public change = (): void => undefined;

  public get loading() {
    return this.signInLoading || this.roleLoading;
  }

  public get userEmail() {
    return this.email;
  }

  public get authorized() {
    return !!this.token;
  }

  public get user(): UserData {
    return this.userData;
  }

  public get accessToken() {
    return this.token;
  }

  public get isPlanExists() {
    return this.hasPlan;
  }

  public get error() {
    if (this.errorResponse) {
      return getMessageInError(this.errorResponse) || 'Incorrect username and/or password.';
    }

    return undefined;
  }

  public signIn = async ({ email, password }: SignIn): Promise<SignInData | AxiosError<IDataErrors>> => {
    this.errorResponse = undefined;
    this.signInLoading = true;
    this.change();

    return axios
      .post<IUserResponse>(`${process.env.REACT_APP_API_URL}/users/login`, {
        email,
        password,
      })
      .then(({ data }) => {
        this.email = email;

        this.setData(data);

        return data;
      })
      .catch((e) => {
        this.errorResponse = e;
        Sentry.captureException(e);

        return e;
      })
      .finally(() => {
        this.signInLoading = false;
        this.saveInStore();
        this.change();
      });
  };

  public forgotPassword = async ({ email }: ForgotPassword): Promise<SignInData | AxiosError<IDataErrors>> => {
    this.errorResponse = undefined;
    this.signInLoading = true;
    this.change();

    return axios
      .post<IUserResponse>(`${process.env.REACT_APP_API_URL}/users/forgot-password`, {
        email,
      })
      .catch((e) => {
        this.errorResponse = e;
        Sentry.captureException(e);
        throw e

        return e;
      })
      .finally(() => {
        this.signInLoading = false;
        this.change();
      });
  };

  public resetPassword = async ({
    password,
    forgotPasswordCode,
  }: ResetPassword): Promise<SignInData | AxiosError<IDataErrors>> => {
    this.errorResponse = undefined;
    this.signInLoading = true;
    this.change();

    return axios
      .put<IUserResponse>(`${process.env.REACT_APP_API_URL}/users/accept-forgot-password`, {
        password,
        forgotPasswordCode,
      })
      .catch((e) => {
        this.errorResponse = e;
        Sentry.captureException(e);
        throw e

        return e;
      })
      .finally(() => {
        this.signInLoading = false;
        this.change();
      });
  };

  public signUp = async ({ email, password, nmlsId, ref }: SignUp): Promise<SignInData | AxiosError<IDataErrors>> => {
    this.errorResponse = undefined;
    this.signInLoading = true;

    this.change();

    return axios
      .post<IUserResponse>(`${process.env.REACT_APP_API_URL}/users/register`, {
        email,
        password,
        nmlsId,
        ref,
      })
      .then(({ data }) => {
        this.email = email;

        this.setData(data);

        return data;
      })
      .catch((e) => {
        this.errorResponse = e;
        Sentry.captureException(e);
        throw e

        return e;
      })
      .finally(() => {
        this.signInLoading = false;
        this.saveInStore();
        this.change();
      });
  };

  public reLogin = async (token?: string) => {
    if (this.reLoginPromise) {
      return this.reLoginPromise;
    }

    this.errorResponse = undefined;
    this.signInLoading = true;
    this.change();

    this.reLoginPromise = axios
      .get<IUserResponse>(`${process.env.REACT_APP_API_URL}/auth/refresh/token`, {
        headers: { Authorization: `Bearer ${token || this.refreshToken}` },
      })
      .then(({ data }) => {
        this.setData(data);

        return data;
      })
      .catch((e) => {
        Sentry.captureException(e);
        if (e.response.status === 403) {
          this.logout();
        }

        this.errorResponse = e;
        throw e

        return e;
      })
      .finally(() => {
        this.signInLoading = false;
        this.saveInStore();
        this.change();
        setTimeout(() => {
          this.reLoginPromise = undefined;
        }, 0);
      });

    return this.reLoginPromise;
  };

  public clearResponseError = () => {
    this.errorResponse = undefined;
    this.change();
  };

  public logout = () => {
    this.clear();
    this.change();
  };
}

const auth = new Auth(getDataInStorage('auth').refreshToken ? getDataInStorage('auth') : {});

export const AuthContext = createContext(auth);

export const useAuth = (): Auth => useContext<Auth>(AuthContext);

export default auth;
