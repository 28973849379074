import Icon from '@ant-design/icons';
import { Divider, Popover, Radio, Typography } from 'antd';
import clsx from 'clsx';
import Stripe from 'stripe';
import { FEATURES } from '../../../../Reports/constants';
import { Checked, Info, Close } from '../../../../../Common/Icon';
import styles from './index.module.less';

interface IPlanRow {
  plan: Stripe.Plan;
  withDiscount?: boolean;
  discountPercent: number;
}

export const formatPrice = (price: number) => `$${(price / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

const PlanRow = ({ plan: { id, nickname, amount, interval, metadata, trial_period_days }, withDiscount,
  discountPercent }: IPlanRow) => {

  const formattedPrice = amount && formatPrice(amount);
  const formattedDiscountPrice = amount && formatPrice(amount * (1 - discountPercent / 100));
  const displayInterval = interval === 'year' ? 'yr' : 'mo';

  const renderFeature = (key: string) => metadata && metadata[key] === 'true' ? (
    <Icon component={Checked} className="color-blue fs-1" />
  ) : (
    <Icon component={Close} className="color-red fs-1" />
  );

  return (
    <div className={styles.row}>
      <Radio value={id}>
        <div className={styles.content}>
          <div className="flex-row flex-align-center gap-8">
            <Typography.Title level={3}>{nickname}</Typography.Title>
            <Popover
              content={
                <div className="flex-col">
                  <Typography.Text className="fs-0875 color-dark fw-500">{nickname}</Typography.Text>
                  <div className="flex-row flex-align-baseline gap-16">
                    <Typography.Text className="fs-25 color-dark-blue fw-600">
                      {withDiscount && !trial_period_days && (
                        <Typography.Title level={3} className={styles.price}>
                          {formattedDiscountPrice}/{displayInterval}
                        </Typography.Title>
                      )}
                    </Typography.Text>
                  </div>
                  {metadata && !!Object.entries(metadata).length && (
                    <>
                      <Divider style={{ marginBlock: 12 }} />
                      <div className="flex-col gap-16">
                        {Object.keys(FEATURES).map(key => (
                          <div key={key} className="flex-row gap-12 flex-align-center">
                            {renderFeature(key)}
                            <Typography.Text className="fs-0875 color-gray">
                              {FEATURES[key]}
                            </Typography.Text>
                          </div>
                        ))}
                        {Object.entries(metadata)
                          .filter(([key]) => key.includes('note'))
                          .map(([key, value]) => (
                            <div key={key} className="flex-row gap-12 flex-align-center">
                              <Typography.Text className="fs-075 color-blue">*</Typography.Text>
                              <Typography.Text className="fs-075 color-gray">{value}</Typography.Text>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              }
            >
              <Icon component={Info} className="color-gray fs-125" />
            </Popover>
          </div>

          <div className="flex-row flex-align-center gap-12">
            {
              withDiscount && !trial_period_days && (
                <Typography.Title level={3} className={styles.price}>
                  ${amount
                    ? (amount / 100 - (amount / 100) * (discountPercent / 100)).toFixed(2)
                    : 0
                  }/{interval.slice(0, 2)}
                </Typography.Title>
              )
            }
            <Typography.Title
              level={3}
              className={clsx(styles.price, {
                'line-through color-gray': withDiscount && !trial_period_days,
              })} >
              {formattedPrice}/{displayInterval}
            </Typography.Title>
          </div>
        </div>
      </Radio>
    </div>
  )
};

PlanRow.defaultProps = {
  withDiscount: false,
};

export default PlanRow;
