import { Button, Col, Input, Radio, RadioChangeEvent, Row, Space, Switch, Typography } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { useAuth } from '../../../../../context/auth';
import { useContextNotifications } from '../../../../../context/notifications';
import { useCheckCoupon, usePlans } from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import Loading from '../../../../Common/Loading';
import PlanRow from './PlanRow';

interface ISelectPlan {
  handleNext: (id: string, promoCode?: string) => void;
  setDiscount: (item: any) => void;
}

const SelectPlan = ({ handleNext, setDiscount }: ISelectPlan): JSX.Element => {
  const getPlans = usePlans();
  const checkpromoCode = useCheckCoupon();
  const [currentPlan, setCurrentPlan] = useState<string>('');
  const [plans, setPlans] = useState<Stripe.Plan[]>();
  const [promoCode, setpromoCode] = useState<string>();
  const [validpromoCode, setValidpromoCode] = useState<string>();
  const [discountPercent, setDiscountPercent] = useState<number>(0);
  const [isYearly, setIsYearly] = useState<boolean>(false)
  const { openNotification } = useContextNotifications();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/sign-up');
    logout();
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansResponse = await getPlans.fetch({ type: isYearly ? 'year' : 'month' });
        const planData = plansResponse?.data || [];

        if (planData.length > 0) {
          const sortedPlans = [...planData].sort((a, b) => (a.amount || 0) - (b.amount || 0));

          setPlans(sortedPlans);
          setCurrentPlan(sortedPlans[0]?.id);
        }
      } catch (error) {
        openNotification?.({ message: `Error fetching plans: ${error}`, type: 'error' });
      }
    };

    fetchPlans();
  }, [isYearly]);

  const handleChange = (event: RadioChangeEvent) => {
    const { value } = event.target;

    setCurrentPlan(value);
  };

  const handleNextClick = () => {
    handleNext(currentPlan, validpromoCode);
  };

  const handlepromoCodeChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setpromoCode(target.value);
  };

  const handleApplypromoCode = () => {
    if (!promoCode) return;

    checkpromoCode.fetch({ promoCode }).then((res: any) => {
      if (res?.data && res?.data?.validPromoCode.coupon.percent_off) {
        setDiscountPercent(res.data.validPromoCode.coupon.percent_off);
        setDiscount(res.data.validPromoCode.coupon.percent_off)
      }
      setValidpromoCode(promoCode);
      openNotification?.({ message: 'promoCode was applied successfully!', type: 'success' });
    }).catch(() => setpromoCode(""))
  };

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        justifyItems: 'end',
        gap: '12px',
        paddingRight: '3px'
      }}>
        <Switch
          checked={isYearly}
          onChange={() => setIsYearly(!isYearly)}
        />
        <Typography.Text className="fw-600 color-gray">Yearly</Typography.Text>
        <div
          style={{
            padding: '2px 10px',
            color: 'var(--color-blue)',
            border: '1px solid var(--color-blue)',
            borderRadius: 4,
          }}
        >
          20% OFF
        </div>
      </div>
      <ContentCard loading={getPlans.loading}>
        <Loading visible={checkpromoCode.loading} absolute />
        <div className="flex-col gap-40">
          <Radio.Group value={currentPlan} onChange={handleChange}>
            <Space direction="vertical" size={10}>
              {plans && plans.map((plan) => <PlanRow key={plan.id} plan={plan} withDiscount={!!validpromoCode}
                discountPercent={discountPercent} />)}
            </Space>
          </Radio.Group>
          <div className="flex-row gap-8">
            <Input size="large" placeholder="Have a discount code?"
              onChange={handlepromoCodeChange} value={promoCode} />
            <Button size="large" type="primary" disabled={!promoCode} onClick={handleApplypromoCode}>
              Apply
            </Button>
          </div>
        </div>
      </ContentCard>
      <Row gutter={24}>
        <Col span={12}>
          <Button size="large" type="default" block onClick={handleLogout} disabled={getPlans.loading}>
            Back
          </Button>
        </Col>
        <Col span={12}>
          <Button
            size="large"
            type="primary"
            block
            onClick={handleNextClick}
            disabled={getPlans.loading || checkpromoCode.loading}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SelectPlan;
