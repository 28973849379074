import { Button } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContextNotifications } from '../../../../context/notifications';
import { IReportInsuranceParams, useReportRequestInsurance } from '../../../../hooks/reports';
import GetQuoteModal from './GetQuoteModal';
import { useContextReports } from '../../../../context/reports';

const GetQuote = () => {
  const params = useParams();
  const reportRequestInsurance = useReportRequestInsurance();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { openConfirm } = useContextNotifications();
  const {report } = useContextReports()

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOk = (values: IReportInsuranceParams) => {
    reportRequestInsurance.fetch({ ...values, reportId: params.id });

    handleModalClose();
    openConfirm?.({
      title: 'Thank you!',
      content: 'Thank you for your request.  A copy of your quote will be emailed to you soon.',
      okText: 'Ok',
    });
  };

  return (
    <div>
      <GetQuoteModal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalClose} />
      {report?.User?.insurance && (
        <Button type="default" block={false} onClick={handleModalOpen}>
          Get a Quote
        </Button>
      )}
    </div>
  );
};

export default GetQuote;
