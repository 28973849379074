import { Button, Modal, Typography } from 'antd';

interface ModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    id: string;
    handleChange: (id: string) => void;
}

const ConfirmModal: React.FC<ModalProps> = ({ isOpen, id, toggleModal, handleChange }) => {
    const handleClick = ()=>{
        handleChange(id)
        toggleModal()
    }

  return ( 
     <Modal closable={false}
        open={isOpen}
        onCancel={toggleModal}
        footer={[
            <Button key="yes" onClick={handleClick}>
                Yes
            </Button>,
            <Button key="no" type="primary" onClick={toggleModal}>
                No
            </Button>,
        ]}>
        <div className="flex-col  gap-16">
            <Typography className="text-l">
                Are you Sure want to change the Plan?
            </Typography>
        </div>
    </Modal>
)}

export default ConfirmModal;