import { Alert, Button, Col, Form as AntdForm, Input, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { login, password } from '../../../../utils/inputRules';
import styles from '../index.module.less';

function SignIn(): JSX.Element {
  const navigate = useNavigate();
  const { authorized, signIn, loading, error, isPlanExists, clearResponseError } = useAuth();

  useEffect(() => {
    if (!authorized) return;
    if (isPlanExists) {
      navigate('/home', { replace: true });
    } else {
      navigate('/setup', { replace: true });
    }
  }, [authorized, isPlanExists]);

  useEffect(() => {
    clearResponseError();
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  return (
    <AntdForm layout="vertical" onFinish={signIn} className={styles.form} autoComplete="off">
      {error ? (
        <AntdForm.Item>
          <Alert onClose={clearResponseError} message={error} type="error" closable showIcon />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item name="email" label="Email" rules={login}>
        <Input size="large" placeholder="Enter Email"  autoFocus
          onKeyDown={handleKeyDown}/>
      </AntdForm.Item>
      <AntdForm.Item name="password" label="Password" rules={password} hasFeedback>
        <Input.Password size="large" placeholder="Enter Password" 
          onKeyDown={handleKeyDown} />
      </AntdForm.Item>
      <AntdForm.Item>
        <div className={styles.textBox}>
          <Typography.Text className="color-gray">
            <Link to="/forgot-password">Forgot Password?</Link>
          </Typography.Text>
        </div>
      </AntdForm.Item>
      <AntdForm.Item shouldUpdate>
        {({ getFieldsValue, getFieldsError }) => {
          const { email, password: passwordValue } = getFieldsValue();
          const formIsComplete = email && passwordValue && getFieldsError().every((field) => !field.errors.length);

          return (
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={!formIsComplete}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          );
        }}
      </AntdForm.Item>
      <div className={styles.textBox}>
        <Typography.Text className="color-gray">
          Don't have an account? <Link to="/sign-up">Sign Up</Link>
        </Typography.Text>
      </div>
    </AntdForm>
  );
}

export default SignIn;
