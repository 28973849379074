import { Space } from 'antd';
import React, { CSSProperties } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  InteractionMode,
  TooltipItem,
  ChartType,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { commafy } from '../../../../utils/text';
import ContentCard from '../../ContentCard';
import { getCustomTooltip, ICustomTooltip } from '../index';
import ChartLegend from '../Legend';
import { ILineChartData } from '../types';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler);

interface IGetOptions {
  stacked: boolean;
  mode: InteractionMode;
  reversed: boolean;
  suffix?: string;
  prefix?: string;
}
export const getOptions = ({ stacked, mode, reversed, suffix, prefix }: IGetOptions) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      intersect: false,
      external: (context: ICustomTooltip) => getCustomTooltip({ context, mode, isReversed: reversed, suffix, prefix }),
      mode,
      callbacks: {
        label: (item: TooltipItem<ChartType>) => {
          const value = parseFloat(`${item.raw}`).toFixed(3);

          return `${item.dataset.label}, ${value}, ${item.dataset.backgroundColor}`;
        },
      },

    },
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked,
      ticks: {
        color: '#8D94A2',
      },
    },
    y: {
      stacked,
      ticks: {
        color: '#8D94A2',
        callback: (value: string | number) => {
          const formattedValue = prefix === '$' ? commafy(parseFloat(`${value}`).toFixed(3))
            : parseFloat(`${value}`).toFixed(3);

          return `${prefix || ''}${formattedValue}${suffix || ''}`;
        }
      },
      ...(suffix === '%' ? { max: 100 } : {}),
    },
  },
});

interface ILineChart extends CSSProperties {
  data?: ILineChartData;
  children?: React.ReactNode;
  legendControls?: React.ReactNode;
  hideLegend?: boolean;
  stacked?: boolean;
  reversed?: boolean;
  loading?: boolean;
  mode?: InteractionMode;
  suffix?: string;
  prefix?: string;
  id?: string;
}

const LineChart = ({
  id,
  data,
  children,
  hideLegend,
  legendControls,
  stacked = false,
  mode = 'index',
  suffix,
  prefix = '$',
  reversed = false,
  loading = false,
  ...styles
}: ILineChart) => (
  <Space direction="vertical" size={20}>
    {!hideLegend && data && (
      <ChartLegend data={data} reversed={reversed}>
        {legendControls}
      </ChartLegend>
    )}

    {children}

    <ContentCard loading={loading}>
      {data && (
        <div className="chart-wrapper" id={id} style={styles}>
          <Line options={getOptions({ stacked, mode, reversed, suffix, prefix })} data={data} />
        </div>
      )}
    </ContentCard>
  </Space>
);

LineChart.defaultProps = {
  data: undefined,
  children: undefined,
  legendControls: undefined,
  hideLegend: false,
  stacked: false,
  reversed: false,
  loading: false,
  mode: 'index',
  prefix: undefined,
  suffix: undefined,
  id: undefined,
};

export default LineChart;
