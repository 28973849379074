import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { INews, useDeleteNews, useNewsId } from '../../../../hooks/news';
import { Back } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import { MainContent } from './mainContent';
import { Card } from './infoCard';
import NotFound from '../../../../pages/notFound';
import './details.less'
import { useContextNotifications } from '../../../../context/notifications';

const Details = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<INews>()
    const { id: newsId } = useParams();
    const newsByid = useNewsId()
    const newsDelete = useDeleteNews()
    const [update, setUpdate] = useState<boolean>(false)
    const { openNotification } = useContextNotifications();

    const handleNewsDelete = () => {
        if (!data) return
        newsDelete.fetch(data.id.toString()).then(() => {
            openNotification?.({ message: 'News deleted successfully' });
            handleBack()
        });
    };

    const toggleUpdate = () => setUpdate(!update)

    const loadNews = () => {
        newsByid.fetch(undefined, newsId).then((res) => {
            if (!res?.data) return;

            setData(res.data);
        });
    };

    useEffect(() => {
        loadNews();
    }, [newsId, update]);

    const handleBack = () => {
        navigate('/breaking-news');
    };

    if (newsByid.loading) {
        return <Loading
            visible={newsByid.loading}
            absolute
        />
    }

    if (!data) {
        return <NotFound />;
    }

    return (
        <div style={{ padding: '10px' }}>
            <div className='buttonContainer' style={{ justifyContent: 'space-between' }}>
                <Button type="ghost" icon={<Icon component={Back} />} onClick={handleBack} />
                <Button danger type='primary' onClick={handleNewsDelete}>DELETE</Button>
            </div>
            <div style={{
                width: '100%',
                height: '100%',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Card
                    toggleUpdate={toggleUpdate}
                    id={data.id}
                    link={data.link}
                    imgUrl={data.imgUrl}
                    category={data.category}
                    header={data.header}
                    pubDate={data.pubDate}
                    author={data.author}
                    origin={data.origin}
                />
                <MainContent
                    toggleUpdate={toggleUpdate}
                    id={data.id}
                    body={data.body}
                />
            </div>
        </div>
    )
}

export default Details