import { useEffect, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { setDataInSessionStorage, getDataInSessionStorage } from '../../../../utils/storage';

const BorrowerModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [opened, setOpened] = useState<boolean>(false)

  useEffect(() => {
    const hasBeenOpened = getDataInSessionStorage('modalShown');

    if (hasBeenOpened.shown) {
      setOpened(true)
    }
  }, []);

  return (
    !opened ? <Modal
      closable={false}
      footer={null}
      open={isOpen}
      onCancel={() => setIsOpen(!isOpen)}
    >
      <div className="flex-col  gap-16">
        <Typography className="text-l">
          Legal Disclaimer
          <br />
          <br />
          The mortgage loan options provided here are preliminary and for general reference only.
          The actual rate, payment, and costs could be higher.
          Get an official Loan Estimate before choosing a loan. § 1026.19(e)(2)(ii)
        </Typography>
        <div className="flex-row flex-justify-end gap-12">
          <Button type="default" size="large"
            onClick={() => {
              setIsOpen(!isOpen)
              setDataInSessionStorage('modalShown', { shown: true });
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal> : <div />
  )
}

export default BorrowerModal