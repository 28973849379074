import React, { useEffect, useState, useRef } from 'react';
import { Table, TableColumnsType, InputRef } from 'antd';
import { useContextNotifications } from "../../../context/notifications";
import { useGetTableData, TableDataItem } from '../../../hooks/admin';
import { useUserDelete } from '../../../hooks/users';
import { GetColumnSearchProps } from "./search";

import "antd/dist/antd.css";

interface PaginationState {
    current: number;
    pageSize: number;
    total: number;
    sortBy: string | null;
    sortDirection: string | null;
}

const UserTable = () => {
    const [update, handleUpdate] = useState<boolean>(false)
    const [data, setData] = useState<TableDataItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [searchedColumn, setSearchedColumn] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationState>({
        current: 1,
        pageSize: 10,
        total: 0,
        sortBy: null,
        sortDirection: null,
    });
    const deleteUser = useUserDelete();
    const { openNotification } = useContextNotifications();
    const getData = useGetTableData();
    const searchInput = useRef<InputRef>(null);

    const handleDeleteUser = (userId: string) => {
        setLoading(true)
        if (!userId) return;
        deleteUser.fetch(userId)
            .then(() => {
                openNotification?.({ message: 'User deleted successfully' });
                handleUpdate(!update)
            })
            .catch((error) => {
                setLoading(false)
                openNotification?.({ message: `Error while deleting user: ${error}`, type: "error" });
            }).finally(() => {
                setLoading(false)
            });
    };

    const fetchData = () => {
        setLoading(true);
        getData.fetch({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortBy: pagination.sortBy,
            sortDirection: pagination.sortDirection,
            searchText,
            searchedColumn
        })
            .then((response) => {
                setData(response?.data.tableData || []);
                setLoading(false);
                setPagination(prev => ({
                    ...prev,
                    total: response?.data.totalUsers || 0,
                }));
            })
            .catch((error) => {
                setLoading(false);
                openNotification?.({
                    message: `Error while Getting Data: ${error}`,
                    type: 'error',
                });
            });
    };

    useEffect(() => {
        fetchData();
    }, [pagination.current, searchedColumn, searchText, pagination.pageSize, pagination.sortBy,
    pagination.sortDirection, update]);

    const handleTableChange = (newPagination: any, filters: any, sorter: any) => {
        const sortField = sorter.field;
        const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';

        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
            sortBy: sortField,
            sortDirection: sortOrder,
        });
    };

    const COLUMN: TableColumnsType<TableDataItem> = [
        {
            title: 'User ID',
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...GetColumnSearchProps<TableDataItem>({
                dataIndex: 'email',
                indexName: "Email",
                searchText, searchedColumn, setSearchText, setSearchedColumn, searchInput
            }),
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Date created',
            dataIndex: 'createdAt',
            sorter: true,
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'NMLS ID',
            dataIndex: 'nmlsId',
            ...GetColumnSearchProps<TableDataItem>({
                dataIndex: 'nmlsId',
                indexName: 'NMLS ID',
                searchText, searchedColumn, setSearchText, setSearchedColumn, searchInput
            }),
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            sorter: true,
            render: (isActive: boolean) => (isActive ? 'Yes' : 'No'),
        },
        {
            title: 'Reports Created',
            dataIndex: 'reportNumber',
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record: TableDataItem) => <a onClick={() => handleDeleteUser(record.id)}>Delete</a>,
        },
    ];

    return (
        <Table
            columns={COLUMN}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
        />
    );
};

export default UserTable;
