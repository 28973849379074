import { Button, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReferrals } from '../../../../hooks/users';
import ContentCard from '../../../Common/ContentCard';
import Loading from '../../../Common/Loading';
import ReferralRow from './Row';

const Referrals = () => {
  const navigate = useNavigate();
  const { data, fetch, loading } = useReferrals();

  useEffect(() => {
    fetch();
  }, []);

  const handleViewAll = () => {
    navigate('/home/referrals');
  };

  return (
    <ContentCard bordered padding={20} height="100%">
      <Space direction="vertical" size={20}>
        <div className="flex-row flex-justify-space-between gap-20">
          <Typography.Title level={2} style={{color:"#464646"}}>Resent referrals</Typography.Title>
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        </div>
        <div className="position-relative flex-1">
          <Loading visible={loading} absolute />
          {!!data?.data?.referrals.length &&
            data.data.referrals.map((referral, index) => (
              <ReferralRow key={referral.id} data={referral} isLast={index === data.data.referrals.length - 1} />
            ))}
          {!data?.data?.referrals.length && !loading && (
            <Typography.Title level={2} className="color-gray">
              You don't have any referrals
            </Typography.Title>
          )}
        </div>
      </Space>
    </ContentCard>
  );
};

export default Referrals;
