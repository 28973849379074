import Icon from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useState, SetStateAction, Dispatch } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import printJS from 'print-js';
import { jsPDF } from 'jspdf';
import { toPng } from 'html-to-image';

import { useContextNotifications } from '../../../../context/notifications';
import { useContextReports } from '../../../../context/reports';
import { IReportShareParams, useReportShare } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { Download, Print, Share } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import ShareModal from '../../../Pages/Reports/ShareModal';
import styles from '../../index.module.less';
import { IReportRow } from '../../../../types/reports';
import { reportViewToPrint } from '../../../Pages/Reports/constants';

interface IHeaderProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setLoading }: IHeaderProps) => {
  const { id: reportId } = useParams();
  const { report, form } = useContextReports();
  const { openNotification } = useContextNotifications();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false);
  const reportShare = useReportShare();
  const location = useLocation();

  const handleDownloadPdf = async () => {
    setIsPdfLoading(true)

    const reportsElement = document.getElementById('reportToPdf');
    const reportAvatar = document.querySelectorAll('img[alt="User Avatar"]')
    const originalSrc = reportAvatar[0]?.getAttribute('src');

    try {
      if (!reportsElement) return

      if (reportAvatar && originalSrc) {
        reportAvatar.forEach((element) => {
          element.setAttribute("src", "/avatar-placeholder.png")
        });
      }

      reportsElement.setAttribute('style', `height: auto; overflow: visible`);

      const fullHeight = reportsElement.scrollHeight;
      const fullWidth = reportsElement.scrollWidth;

      const originalStyle: string | null = reportsElement.getAttribute('style');

      reportsElement.setAttribute('style', `width: ${fullWidth}px; height: ${fullHeight}px; ${originalStyle}`);

      const dataUrl = await toPng(reportsElement, {
        cacheBust: true,
        skipAutoScale: true,
        width: fullWidth,
        height: fullHeight,
        style: {
          background: 'white',
          width: `${fullWidth}px`,
          height: `${fullHeight}px`
        }
      });

      if (originalStyle) {
        reportsElement.setAttribute('style', originalStyle);
      }
      const pageHeight = (842 * fullWidth) / 595 // aspect ratio as A4
      const numPages = Math.ceil(fullHeight / pageHeight)

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [pageHeight, fullWidth],
        putOnlyUsedFonts: true,
      });

      for (let page = 0; page < numPages; page++) {
        const y = page * pageHeight;

        if (page > 0) {
          pdf.addPage();
        }
        pdf.addImage(dataUrl, 'PNG', 0, -y, fullWidth, fullHeight, undefined, 'FAST');
      }

      pdf.save(`${report?.name ?? 'Report'}`);
      if (reportAvatar && originalSrc) {
        reportAvatar.forEach((element) => {
          element.setAttribute('src', originalSrc);
        });
      }
    } catch (err) {
      openNotification?.({
        message: `Error while generating PDF: ${err}`,
        type: 'error',
      });
    } finally {
      setIsPdfLoading(false)
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleShare = (data: JsonResult) => {
    handleModalClose();
    reportShare.fetch(data as IReportShareParams).then(() => {
      openNotification?.({
        message: 'The report was shared successfully',
        type: 'success',
      });
    });
  };

  const handlePrint = async () => {
    setIsPdfLoading(true)
    setLoading(true);
    const fullHeight = 9500;
    const fullWidth = 1126;
    const pageHeight = (842 * fullWidth) / 595 // aspect ratio as A4
    const numPages = Math.ceil(fullHeight / pageHeight)
    const defaultReportView: IReportRow[] = form && form.getFieldValue('reportView');

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [pageHeight, fullWidth],
      putOnlyUsedFonts: true,
    });

    const reportVIewForPrint: { [key: string]: unknown } = {};

    Object.keys(reportViewToPrint).forEach((key) => {
      const { order, active = true } = reportViewToPrint[key];
      const defaultView = defaultReportView[key as any];

      if (defaultView) {
        reportVIewForPrint[key] = { ...defaultView, order, active };
      }
    });

    form?.setFieldValue('reportView', reportVIewForPrint)

    // eslint-disable-next-line no-promise-executor-return
    new Promise(resolve => setTimeout(resolve, 4000)).then(async () => {

      const reportAvatar = document.querySelectorAll('img[alt="User Avatar"]')
      const originalSrc = reportAvatar[0]?.getAttribute('src');

      const reportsElement = document.getElementById('reportToPdf');
      const chartVsOwn = document.getElementById('chartVsOwn');
      const rentVsOwn = document.getElementById('rentOrOwn');
      const closingCosts = document.getElementById('closingCostsEstimates')
      const taxAdvantage = document.getElementById('taxAdvantage')
      const buyDownCalculator = document.getElementById('buyDownCalculator')
      const longTerm = document.getElementById('longTermInvestmentsNumbers')
      const rentalAnalysis = document.getElementById('rentalAnalysis')
      const interestSavings = document.getElementById('interestSavings')
      const breakEvenPoint = document.getElementById('breakEvenPoint')

      const oringinalChartVsOwn = chartVsOwn ? chartVsOwn.getAttribute('style') : '';
      const originalStyle = reportsElement ? reportsElement.getAttribute('style') : '';
      const originalbuyDownCalculator = buyDownCalculator ? buyDownCalculator.getAttribute('style') : '';
      const originalRentVsOwnStyle = rentVsOwn ? rentVsOwn.getAttribute('style') : '';
      const originaltaxAdvantage = taxAdvantage ? taxAdvantage.getAttribute('style') : '';
      const originallongTerm = longTerm ? longTerm.getAttribute('style') : '';
      const originalrentalAnalysis = rentalAnalysis ? rentalAnalysis.getAttribute('style') : '';
      const originalinterestSavings = interestSavings ? interestSavings.getAttribute('style') : '';
      const originalbreakEvenPoint = breakEvenPoint ? breakEvenPoint.getAttribute('style') : ''
      const originalclosingCost = closingCosts ? closingCosts.getAttribute('style') : ''

      if (reportsElement) reportsElement.setAttribute('style', `${originalStyle}; width:${fullWidth}px; 
        height:${fullHeight}px ; overflow: visible`);

      if (rentVsOwn) rentVsOwn.setAttribute('style', `${originalRentVsOwnStyle}; margin-top: 5px`);

      if (chartVsOwn) chartVsOwn.setAttribute('style', `${oringinalChartVsOwn}; max-height:250px`);

      if (rentalAnalysis) rentalAnalysis.setAttribute('style', `${originalrentalAnalysis}; margin-top:100px`);

      if (taxAdvantage) taxAdvantage.setAttribute('style', `${originaltaxAdvantage}`);

      if (buyDownCalculator) buyDownCalculator.setAttribute('style', `${originalbuyDownCalculator}`);

      if (longTerm) longTerm.setAttribute('style', `${originallongTerm}`);

      if (interestSavings) interestSavings.setAttribute('style', `${originalinterestSavings}; margin-top:200px`);

      if (breakEvenPoint) breakEvenPoint.setAttribute('style', `${originalbreakEvenPoint}`);

      if (closingCosts) closingCosts.setAttribute('style', `${originalclosingCost}; margin-top:40px`);

      try {
        if (!reportsElement) return

        if (reportAvatar && originalSrc) {
          reportAvatar.forEach((element) => {
            element.setAttribute("src", "/avatar-placeholder.png")
          });
        }

        const dataUrl = reportsElement && await toPng(reportsElement, {
          cacheBust: true,
          skipAutoScale: true,
          width: fullWidth,
          height: fullHeight,
          style: {
            background: 'white',
            width: `${fullWidth}px`,
            height: `${fullHeight}px`
          }
        });

        for (let page = 0; page < numPages; page++) {
          const y = page * pageHeight;

          if (page > 0) {
            pdf.addPage();
          }
          pdf.addImage(dataUrl, 'PNG', 0, -y, fullWidth, fullHeight, undefined, 'MEDIUM');
        }

        const base64Pdf = pdf.output('datauristring').split(',')[1];

        if (reportAvatar && originalSrc) {
          reportAvatar.forEach((element) => {
            element.setAttribute('src', originalSrc);
          });
        }

        if (reportsElement) reportsElement.setAttribute('style', originalStyle || '');

        if (rentVsOwn) rentVsOwn.setAttribute('style', originalRentVsOwnStyle || '');

        if (taxAdvantage) taxAdvantage.setAttribute('style', originaltaxAdvantage || '');

        if (buyDownCalculator) buyDownCalculator.setAttribute('style', originalbuyDownCalculator || '');

        if (longTerm) longTerm.setAttribute('style', originallongTerm || '');

        if (rentalAnalysis) rentalAnalysis.setAttribute('style', originalrentalAnalysis || '');

        if (interestSavings) interestSavings.setAttribute('style', originalinterestSavings || '');

        if (breakEvenPoint) breakEvenPoint.setAttribute('style', originalbreakEvenPoint || '');

        if (chartVsOwn) chartVsOwn.setAttribute('style', oringinalChartVsOwn || '');

        if (closingCosts) closingCosts.setAttribute('style', originalclosingCost || '')

        printJS({
          printable: base64Pdf,
          type: 'pdf',
          modalMessage: 'Preparing document for printing...',
          base64: true,
        });
      }
      catch (err) {
        openNotification?.({
          message: `Error while printing: ${err}`,
          type: 'error',
        });
      } finally {
        setIsPdfLoading(false)
      }
    }).then(() => {
      form?.setFieldValue('reportView', defaultReportView)
    }).finally(() => {
      setLoading(true)
    })
  };

  const reloadBack = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/edit`;
  };

  return (
    <header className={styles.header}>
      <Loading visible={reportShare.loading || isPdfLoading} absolute />
      <div className={styles.headerContent}>
        <ShareModal
          open={isModalOpen}
          onCancel={handleModalClose}
          onOk={handleShare}
          data={{ link: window.location.href, reportId }}
        />
        <div className="flex-col gap-8">
          <Typography.Title level={2}>{report?.User?.companyName || 'Company Name'}</Typography.Title>
          {report?.User?.websiteLink && (
            <Typography.Text>
              <Link
                to={
                  report?.User.websiteLink.startsWith('http')
                    ? report?.User.websiteLink
                    : `https://${report?.User.websiteLink}`
                }
                target="_blank"
                className="fs-1 fw-400 color-gray"
              >
                {report?.User.websiteLink}
              </Link>
            </Typography.Text>
          )}
        </div>
        <div className="flex-row flex-align-center gap-8">
          {location.pathname.includes('/preview') &&
            <Button
              type="default"
              size="large"
              className="btn-default-dark-blue"
              onClick={() => reloadBack()}
            >
              Back to Edit
            </Button>}
          <Button
            type="default"
            size="large"
            className="btn-default-dark-blue"
            icon={<Icon component={Share} />}
            style={{ minWidth: 40 }}
            onClick={handleModalOpen}
          />
          <Button
            type="default"
            size="large"
            className="btn-default-dark-blue d-m-none"
            style={{ minWidth: 40 }}
            icon={<Icon component={Print} />}
            onClick={handlePrint}
          />
          <Button type="primary" size="large" className="d-m-none" onClick={handleDownloadPdf} download
          >
            Download PDF
          </Button>

          <Button
            type="default"
            size="large"
            onClick={handleDownloadPdf}
            icon={<Icon component={Download} />}
            style={{ minWidth: 40 }}
            className="d-lg-none d-m-flex btn-default-dark-blue"
            download
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
