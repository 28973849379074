import React from 'react';
import Icon from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import { useContextReports } from '../../../../../../../context/reports';
import { IReportRow } from '../../../../../../../types/reports';
import { Info, Paint } from '../../../../../../Common/Icon';
import styles from '../../index.module.less';

interface IHeaderCol {
  disabled: boolean;
}

const HeaderCol = ({ disabled }: IHeaderCol) => {
  const form = useFormInstance();
  const { handleReportSettingsUpdate, isBorrower } = useContextReports();
  const tableView: IReportRow[] = useWatch('tableView', form);
  const highlightColor = getComputedStyle(document.documentElement).getPropertyValue('--color-highlight-yellow');

  const determineBackgroundColor = (row: IReportRow) => {
    if (row.name === 'total' && !row.color) {
      return '#F5F5F9';
    }
    if (row.color) {
      return row.color;
    }

    return 'white';
  }

  const rowColorChange = (name: string) => {
    const color = form.getFieldValue(['tableView', name, 'color']);

    form.setFieldValue(['tableView', name, 'color'], color ? null : highlightColor);

    handleReportSettingsUpdate?.('tableView');
  };

  return (
    <div className={clsx(styles.tableCol, styles.tableHeadCol)}>
      <div className={styles.tableHeadCell} style={{ backgroundColor: "#F5F5F9" }} >
        <Typography.Title level={4}>loan details</Typography.Title>
      </div>
      {tableView &&
        Object.values(tableView)
          ?.filter((row) => row.active)
          ?.sort((a, b) => a.order - b.order)
          ?.map((row) => (
            <div
              key={row.name}
              className={styles.tableCell}
              style={{
                ...row.styles,
                backgroundColor: determineBackgroundColor(row)
              }}
            >
              <div className={clsx(styles.content, { [styles.withActions]: !disabled })}>
                <div className="flex-col gap-8 overflow-hidden">
                  <div className={styles.titleBox}>
                    <Typography.Title className={clsx(styles.title, { [styles.bolded]: row.bolded })} level={4}>
                      {row.text}
                    </Typography.Title>
                    {!!row?.details && (
                      <Popover content={row.details()}>
                        <Icon component={Info} className={styles.details} />
                      </Popover>
                    )}
                  </div>
                  {row.extra}
                </div>
              </div>
              {!disabled && !isBorrower && (
                <div className={styles.actions} style={
                  { background: determineBackgroundColor(row) }}>
                  <div className={styles.list}>
                    <Popover content="Highlight row">
                      <Icon component={Paint} className={styles.icon} onClick={() => rowColorChange(row.name)} />
                    </Popover>
                  </div>
                </div>
              )}
            </div>
          ))}
    </div>
  );
};

export default HeaderCol;
