import { Col, Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useReportCount } from '../../../../hooks/reports';
import ContentCard from '../../../Common/ContentCard';
import Loading from '../../../Common/Loading';
import Tile from './Tile';

const Performance = () => {
  const { data, fetch, loading } = useReportCount();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <ContentCard bordered padding={20} height="100%">
      <Loading visible={loading} absolute />
      <Space direction="vertical" size={20}>
        <Typography.Title level={2} style={{color:'#464646'}} >Report Statistics</Typography.Title>
        <div>
          <Row className="border-bottom">
            <Col span={12} className="border-right">
              <Tile
                label="Report actions"
                value={data?.data.sendReportsCount || 0}
                performanceValue={data?.data.reportsTillNowDiffCount.send}
              />
            </Col>
            <Col span={12}>
              <Tile
                label="Report views"
                value={data?.data.openReportsCount || 0}
                performanceValue={data?.data.reportsTillNowDiffCount.open}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} className="border-right">
              <Tile
                label="Reports created"
                value={data?.data.createdReportsCount || 0}
                performanceValue={data?.data.reportsTillNowDiffCount.created}
              />
            </Col>
            <Col span={12}>
              <Tile
                label="Referrals"
                value={data?.data.referallsCount || 0}
                performanceValue={data?.data.referralsTillNowDifCount}
              />
            </Col>
          </Row>
        </div>
      </Space>
    </ContentCard>
  );
};

export default Performance;
