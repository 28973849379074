import { Button, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { useReports } from '../../../../hooks/reports';
import ContentCard from '../../../Common/ContentCard';
import Loading from '../../../Common/Loading';
import ReportRow from './Row';

const Reports = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data, fetch, loading } = useReports();

  useEffect(() => {
    fetch({ userId: user.id });
  }, []);

  const handleViewAll = () => {
    navigate('/home/activities');
  };

  return (
    <ContentCard bordered padding={20} height="100%">
      <div className="flex-col gap-20">
        <div className="flex-row flex-justify-space-between gap-20">
          <Typography.Title level={2} style={{color:'#464646'}}>Recent reports</Typography.Title>
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        </div>
        <div className="position-relative flex-1">
          <Loading visible={loading} />
          {data?.data.rows && !!data.data.rows.length && (
            <div>
              {data.data.rows.map((report, index) => (
                <ReportRow key={report.id} report={report} isLast={index === data.data.rows.length - 1} />
              ))}
            </div>
          )}
          {!data?.data?.rows.length && !loading && (
            <Typography.Title level={2} className="color-gray">
              You don't have any activities
            </Typography.Title>
          )}
        </div>
      </div>
    </ContentCard>
  );
};

export default Reports;
