import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import Wrapper from '../index';
import Confirm from './Confirm';
import Payment from './Payment';
import SelectPlan from './SelectPlan';
import { getDataInStorage } from '../../../../utils/storage';

const Setup = () => {
  const navigate = useNavigate();
  const { isPlanExists } = useAuth();

  const [key, setKey] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [promoCode, setpromoCode] = useState<string>();
  const [discount, setDiscount] = useState<string>();

  const hasPLan = getDataInStorage("auth").hasPlan

  useEffect(() => {
    if (hasPLan) {
      navigate('/', { replace: true });
    }
  }, [isPlanExists]);

  const handleNext = () => {
    setKey((prev) => prev + 1);
  };

  const handleBack = () => {
    setKey((prev) => prev - 1);
  };

  const handlePlanChange = (id: string, newpromoCode?: string) => {
    setSelectedPlan(id);
    setpromoCode(newpromoCode);
    handleNext();
  };

  const handlePaymentMethodSelect = (id: string | null) => {
    setSelectedPaymentMethod(id);
    handleNext();
  };

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '',
    },
    {
      key: '1',
      label: '',
      children: (
        <Wrapper title="Select plan">
          <SelectPlan setDiscount={setDiscount} handleNext={handlePlanChange} />
        </Wrapper>
      ),
    },
    {
      key: '2',
      label: '',
      children: (
        <Wrapper title="Add payment method" text="Get Mortgage Maker to manage your work">
          <Payment
            selectedPaymentMethod={selectedPaymentMethod}
            handleNext={handlePaymentMethodSelect}
            handleBack={handleBack}
          />
        </Wrapper>
      ),
    },
    {
      key: '3',
      label: '',
      children: (
        <Wrapper title="Confirm your details">
          <Confirm
            selectedPlan={selectedPlan}
            promoCode={promoCode}
            selectedPaymentMethod={selectedPaymentMethod}
            handleBack={handleBack}
            discount={discount}
          />
        </Wrapper>
      ),
    },
  ];

  return (
    <Tabs
      tabPosition="bottom"
      centered
      tabBarGutter={10}
      tabBarStyle={{ borderRadius: 4, height: 5 }}
      className="steps"
      activeKey={key.toString()}
      items={items}
    />
  );
};

export default Setup;
