/* eslint-disable no-unused-expressions */
import Icon from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { memo, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../../context/reports';
import { IReportRow } from '../../../../../../types/reports';
import DraggableListItem from '../../../../../Common/DraggableListItem';
import { EyeOff } from '../../../../../Common/Icon';
import styles from '../index.module.less';
import { ISettingItem } from '../../../../../../hooks/users';

const Customize = () => {
  const form = useFormInstance();
  const tableView: IReportRow = useWatch('tableView', form);
  const { handleReportSettingsUpdate } = useContextReports();
  const [itemList, setItemList] = useState<ISettingItem[]>([]);

  useEffect(() => {
    tableView && setItemList([...Object.values(tableView)].sort((a, b) => a.order - b.order));
  }, [tableView]);

  useEffect(() => {
    if (!itemList) return;

    const data: { [key: string]: boolean } = {};

    itemList.forEach((item) => {
      data[item.id] = item.active;
    });

    form.setFieldsValue(data);
  }, [itemList]);

  const handleDragEnd = (droppedItem: DropResult) => {
    if (!droppedItem.destination) return;
    const updatedList = [...itemList];

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    const reorderedList = updatedList.map((item, index) => ({ ...item, order: index + 1 }));

    reorderedList.forEach((row, index) => {
      form.setFieldValue(['tableView', row.name], { ...row, order: index + 1 });
    });

    handleReportSettingsUpdate?.('tableView');
    setItemList(reorderedList);
  };

  const hiddenFields = tableView ? Object.values(tableView)?.filter((row) => !row.active) : [];

  return (
    <Popover
      placement="bottomRight"
      showArrow={false}
      content={
        <div className={styles.popperContent}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="rows">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {itemList && itemList.map((row, index) => (
                    <Draggable key={row.name} draggableId={row.name} index={index}>
                      {(draggableProvided) => (
                        <DraggableListItem
                          provided={draggableProvided}
                          name={['tableView', row.name, 'active']}
                          text={row.text}
                          border={0}
                          padding={4}
                          draggable
                          onChange={() => handleReportSettingsUpdate?.('tableView')}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      }
      trigger="click"
    >
      <Button ghost type="default" size="large" icon={<Icon component={EyeOff} />}>
        {hiddenFields?.length
          ? `${hiddenFields.length} hidden field${hiddenFields.length > 1 ? 's' : ''}`
          : 'Customize'}
      </Button>
    </Popover>
  );
};

export default memo(Customize, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps));
