export interface JsonResult {
  // eslint-disable-next-line
  [key: string]: any;
}

export interface RequestResult {
  loading: boolean;
  error: Error | null;
}

export interface FetchError {
  message: string | string[];
}

export interface FetchSuccess {
  success: boolean;
}

export interface FetchResponse<T> {
  success: boolean;
  data: T;
}

export interface PagingDataResponse<I> {
  data: I[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
}

export interface Option {
  value: string | boolean | number;
  label: string | JSX.Element;
}

export interface ModalState {
  type: ModalTypes;
  id?: string;
  data?: JsonResult;
  onOk: (data: JsonResult, handleClose: () => void) => void;
  afterClose?: () => void ;
}

export enum ModalTypes {
  'share' = 'share',
  'getQuote' = 'getQuote',
}

export interface ITableParams {
  openModal?: ((modal: ModalState) => void) | undefined;
}
