import { Col, Row, Switch, Typography } from 'antd';
import Form from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import ContentCard from '../../../../Common/ContentCard';
import { useContextReports } from '../../../../../context/reports';
import styles from './index.module.less';
import TransitionSection from './Transition';

interface IReportSection {
  title: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  name: string;
  provided?: DraggableProvided;
  id?: string;
}

const ReportSection = ({ title, description, name, children, headerContent, provided, id }: IReportSection) => {
  const { isBorrower } = useContextReports();
  const { handleReportSettingsUpdate } = useContextReports();

  const form = useFormInstance();

  const isSectionActive = useWatch(['reportView', name, 'active'], form);

  const onSectionActiveChange = () => {
    handleReportSettingsUpdate?.('reportView');
  };

  return (
    <section id={id} className="content-md" style={{ border: "1px solid #F0F5FF", borderRadius: "12px" }}>
      <ContentCard bordered padding={10} background='#1E0C68' color='#FFFFFF'>
        <div className="flex-col gap-4">
          <div className="flex-row flex-justify-space-between flex-align-center" {...provided?.dragHandleProps}>
            <Typography.Title color='#FFFFFF' level={2}>{title}</Typography.Title>
            <Form.Item name={['reportView', name]} noStyle />
            {!isBorrower && (
              <Form.Item name={['reportView', name, 'active']} noStyle>
                <Switch style={{ backgroundColor: isSectionActive ? '#007DFE' : '#ababab' }}
                  size='small' checked={isSectionActive} onChange={onSectionActiveChange} />
              </Form.Item>
            )}
          </div>
          <div className={clsx(styles.section, { [styles.hidden]: !isSectionActive })}>
            <div className={styles.contentWrapper}>
              {description && (
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ width: '100%' }}>
                    {typeof description === 'string' ? (
                      <Typography.Text style={{ color: "#A5B5D4" }}
                        className="fs-0875">{description}</Typography.Text>
                    ) : (
                      description
                    )}
                  </Col>
                  {headerContent}
                </Row>
              )}
            </div>
          </div>
        </div>
      </ContentCard>
      <TransitionSection isSectionActive={isSectionActive} >
        {children}
      </TransitionSection>
    </section>
  );
};

ReportSection.defaultProps = {
  description: undefined,
  children: undefined,
  headerContent: undefined,
  provided: undefined,
  id: undefined,
};

export default ReportSection;
