import Title from 'antd/lib/typography/Title';
import Layout from '../../components/Layout/Main';
import { displayName } from '../../config';
import NewsList from '../../components/Pages/News/newsList';

const breakingNews = () => {
  document.title = `${displayName}: Breaking News`;

  return (
    <Layout >
       <Title style={{padding:"12px"}}> Breaking News</Title>
      <NewsList/>
    </Layout>
  );
};

export default breakingNews;
