import Icon from '@ant-design/icons';
import { Space, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';
import ContentCard from '../../../../Common/ContentCard';
import { LineChartDown, LineChartUp } from '../../../../Common/Icon';

interface ITile {
  label: string;
  value: string | number;
  performanceValue?: number;
}
const Tile = ({ label, value, performanceValue }: ITile) => (
  <ContentCard padding={20}>
    <Space direction="vertical" size={20}>
      <Typography.Text className="color-gray">{label}</Typography.Text>
      <div className="flex-row flex-justify-space-between gap-20">
        <Typography.Title style={{color:'#464646'}} level={2} className="fs-25">
          {value}
        </Typography.Title>
        {!!performanceValue && (
          <div
            className={clsx('flex-row', 'gap-4', 'flex-align-center', {
              'color-green': performanceValue > 0,
              'color-red': performanceValue < 0,
            })}
          >
            <Icon component={performanceValue > 0 ? LineChartUp : LineChartDown} className="fs-125" />
            <Typography.Text
              className={clsx({
                'color-green': performanceValue > 0,
                'color-red': performanceValue < 0,
              })}
            >
              {Math.abs(performanceValue)}
            </Typography.Text>
          </div>
        )}
      </div>
    </Space>
  </ContentCard>
);

Tile.defaultProps = {
  performanceValue: undefined,
};

export default Tile;
