import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import MarketRates from './MarketRates';
import Performance from './Performance';
import Referrals from './Referrals';
import Reports from './Reports';
import { useContextUsers } from '../../../context/users';
import { JsonResult } from '../../../types';
import { useReportCreate, useReportTrackActivities } from '../../../hooks/reports';
import Loading from '../../Common/Loading';

const Home = (): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)
  const { profile, getProfile } = useContextUsers();
  const reportCreate = useReportCreate();
  const reportTrackActivities = useReportTrackActivities();

  const handleReportGenerate = () => {
    setLoading(true)
    const loanColor: JsonResult = {};

    reportCreate.fetch({ name: '', status: 'draft', loanColor }).then((res) => {
      if (!res?.data.id) return;

      reportTrackActivities.fetch({ id: res.data.id, type: 'created' }).then(() => {
        navigate(`/reports/${res.data.id}/edit`)
      });
    }).finally(() => {
      setLoading(false)
    });
  };

  useEffect(() => {
    if (profile) return

    getProfile?.()
  }, [])

  let hasMarketsPermission = true;

  if (profile && profile.permissions) {
    const permissionsObj = JSON.parse(profile.permissions);

    hasMarketsPermission = profile.type === 'admin' || Object.prototype.hasOwnProperty.call(permissionsObj, 'Markets')
  }

  return (
    <div className="flex-col gap-12 content-lg">
      <Loading visible={loading} absolute />
      <div className="table-header">
        <Typography.Title style={{color:'#464646'}} level={2}>Home</Typography.Title>
        <Space>
          <Button type="primary" size="large" onClick={handleReportGenerate}>
            Create Report
          </Button>
        </Space>
      </div>
      <Row gutter={[20, 20]}>
        {hasMarketsPermission && (
          <Col xl={16} span={24}>
            <MarketRates />
          </Col>
        )}
        <Col xl={!hasMarketsPermission ? 12 : 8} span={24}>
          <Performance />
        </Col>
        <Col xl={!hasMarketsPermission ? 12 : 8} span={24}>
          <Referrals />
        </Col>
        <Col xl={16} span={24}>
          <Reports />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
