import { Button, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextNotifications } from '../../../../../context/notifications';
import { useContextReports } from '../../../../../context/reports';
import { copyTextToClipboard } from '../../../../../utils/text';
import ReportSection from '../Section';

interface IReferral {
  provided?: DraggableProvided;
}

const Referral = ({ provided }: IReferral) => {
  const name = 'referral';
  const { openNotification } = useContextNotifications();
  const { report } = useContextReports();

  const link = `${window.location.origin}/referral/${report?.id}`;

  return (
    <ReportSection
      name={name}
      title="Refer your friends and family to get them on the path to homeownership!"
      provided={provided}>
      <Col style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
        span={24} className="flex-col flex-align-self-end">
        <Row justify='center' style={{ width: '500px', margin: "5px", justifyContent: "center" }} >
          <Typography.Title style={{ fontSize: "18px", lineHeight: "28px", marginBottom: "5px" }}>
            Do you know someone who wants to buy a home?
          </Typography.Title>
          <Typography.Text style={{ color: "#4D586E", textAlign: 'center' }}>
            Share this link, and we'll help
            them get started on their journey. As a referral-driven business, we're honored to
            help your friends and family achieve their homeownership goals.
          </Typography.Text>
        </Row>
        <Row style={{ margin: "8px" }}>
          <div className="input-row-with-button w-100">
            <Input value={link} size="middle" placeholder="Report Link" disabled />
            <Button
              type="primary"
              size="middle"
              onClick={() =>
                copyTextToClipboard(link).then(() =>
                  openNotification?.({ message: 'The link is copied to the clipboard' })
                )
              }
            >
              Copy link
            </Button>
          </div>
        </Row>
      </Col>
    </ReportSection >
  );
};

Referral.defaultProps = {
  provided: undefined,
};

export default Referral;
