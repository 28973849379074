import { Col, Row, Space, Radio, RadioChangeEvent } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import { useReportLongTerm } from '../../../../../hooks/reports';
import { commafy } from '../../../../../utils/text';
import BarChart from '../../../../Common/Charts/BarChart';
import { IBarChartData } from '../../../../Common/Charts/types';
import MovingTo from '../../../../Common/MovingTo';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';

interface IDataSet {
  label: string;
  name: 'taxBeretta' | 'appreciationGain' | 'amortisationGain';
  color: string;
  details?: string;
}

const datasets: IDataSet[] = [
  {
    label: 'Tax Benefit',
    name: 'taxBeretta',
    color: '#1E0C68',
    details:
      'This pertains to the significant annual deductions you may receive on your income taxes by paying your ' +
      'mortgage interest and property taxes (depreciation is not included in this estimate).  Essentially, this ' +
      'number represents the amount of money you may save in the long run through income tax deductions associated ' +
      'with owning a home.',
  },
  {
    label: 'Appreciation Gain',
    name: 'appreciationGain',
    color: '#BDE7F1',
    details:
      "This is the projected annual increase in your property's value. For our calculations, we're " +
      "using the national average appreciation rate of 5.77%. In simpler terms, it's the potential profit you " +
      'stand to make each year simply by owning your home, as its market value increases',
  },
  {
    label: 'Amortization Gain',
    name: 'amortisationGain',
    color: '#22A4C9',
    details:
      "Consider this as your personal wealth-building strategy. This is the total sum that you've " +
      'successfully allocated towards reducing your primary loan balance.  Initially, a larger portion of your ' +
      'payment goes towards interest, but as time passes, more of your payment starts chipping away at the ' +
      "principal amount.  It's akin to a forced savings account; each monthly payment increases the equity in " +
      'your home.',
  },
];

interface ILongTermInvestments {
  provided?: DraggableProvided;
}

const LongTermInvestments = ({ provided }: ILongTermInvestments) => {
  const name = 'longTermInvestmentsNumbers';
  const [years, setYears] = useState<number>(15);
  const [data, setData] = useState<IBarChartData>();
  const { loans } = useContextReports();

  const reportLongTerm = useReportLongTerm();

  const form = useFormInstance();
  const movingTo = useWatch(['movingTo', name], form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);

  useEffect(() => {
    if (!loans || !movingTo || !isSectionActive) return;
    const loan = loans.find(item => item.id === movingTo);

    const delay = 800;
    const timeoutId = setTimeout(() => {
      reportLongTerm.fetch({ years }, loan ? loan.id : loans[0].id).then((response) => {
        const labels = Array.from({ length: years }, (_, i) => moment().year() + i);

        const newData: IBarChartData = {
          labels: labels.filter((label, index) => (window.outerWidth < 768 ? index % 2 === 0 : true)),
          datasets: datasets.map((dataset) => ({
            name: dataset.name,
            label: dataset.label,
            data: (response?.data[`${dataset.name}Array`] || []).filter((label, index) =>
              window.outerWidth < 768 ? index % 2 === 0 : true
            ),
            maxBarThickness: 15,
            inflateAmount: 1,
            backgroundColor: dataset.color,
            borderRadius: {
              topLeft: 4,
              topRight: 4,
            },
            total: `$${commafy(response?.data.totals[`${dataset.name}Total`] || 0)}`,
            details: dataset.details,
          })),
        };

        setData(newData);
      });
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);

  }, [loans, years, movingTo, isSectionActive]);

  const handleYearsChange = (e: RadioChangeEvent) => {
    setYears(e.target.value);
  };

  const getTotalGains = () => {
    const totals = reportLongTerm.data?.data.totals;

    if (!totals) return 0;

    const totalGains = Object.values(totals)
      .reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
      .toFixed(0);

    return commafy(totalGains);
  };

  return (
    <ReportSection id={name} name={name} title="Long-Term Investments Numbers" provided={provided}>
      <Space style={{padding:"10px"}} direction="vertical" size={20}>
        <Row>
          <Col md={12} span={24}>
            <TextWithLabel label={`Total gains after ${years} years`} value={`$${getTotalGains()}`} />
          </Col>
          <Col md={12} span={24}>
            <MovingTo name={name} />
          </Col>
        </Row>

        <BarChart data={data} stacked reversed loading={!reportLongTerm.data && reportLongTerm.loading} showTotal>
          <Radio.Group onChange={handleYearsChange} value={years} style={{ marginBottom: 8 }} size="large">
            <Radio.Button value={15}>15 years</Radio.Button>
            <Radio.Button value={30}>30 years</Radio.Button>
          </Radio.Group>
        </BarChart>
      </Space>
    </ReportSection>
  );
};

LongTermInvestments.defaultProps = {
  provided: undefined,
};

export default LongTermInvestments;
