import Icon from '@ant-design/icons';
import { Button, Popover, Switch, Typography } from 'antd';
import React from 'react';
import { EyeOff } from '../../../../../Common/Icon';
import styles from '../../LoanComparisons/index.module.less';

export interface IDataSet {
  id: number;
  label: string;
  name: 'avg' | 'bedrooms';
  value: boolean | number;
  color: string;
  isActive: boolean;
}

interface ICustomize {
  rows: IDataSet[];
  handleRowChange: (id: number, isActive: boolean) => void;
}
const Customize = ({ rows, handleRowChange }: ICustomize) => {
  const hiddenFields = rows.filter((row) => !row.isActive);
  const isMobileView = window.outerWidth < 768;

  return (
    <Popover
      placement="bottomRight"
      showArrow={false}
      content={
        <div className="flex-col gap-8" style={{ minWidth: 170 }}>
          {rows.map((row) => (
            <div key={row.id} className="flex-row flex-align-center gap-8">
              <Switch checked={row.isActive} onChange={(checked) => handleRowChange(row.id, checked)} />
              <Typography className={styles.label}>{row.label}</Typography>
            </div>
          ))}
        </div>
      }
      trigger="click"
    >
      <Button style={{ minWidth: 40 }} type="default" size="large" icon={<Icon component={EyeOff} />}>
        {!isMobileView &&
          (hiddenFields.length
            ? `${hiddenFields.length} hidden parameter${hiddenFields.length > 1 ? 's' : ''}`
            : 'Customize')}
      </Button>
    </Popover>
  );
};

export default Customize;
