/* eslint-disable no-unsafe-optional-chaining */
import Icon from '@ant-design/icons';
import { Col, Row, Slider, Typography } from 'antd';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { JsonResult } from '../../../../../types';
import { commafy } from '../../../../../utils/text';
import LineChart from '../../../../Common/Charts/LineChart';
import { ILineChartData } from '../../../../Common/Charts/types';
import ContentCard from '../../../../Common/ContentCard';
import {
  RENT_VS_OWN,
  RENT_VS_OWN_CHART_DATA,
  RENT_VS_OWN_DATA,
  RENT_VS_OWN_DATASETS,
  RENT_VS_OWN_RESULT
} from '../../constants';
import ReportSection from '../Section';
import TextWithLabel from '../../../../Common/TextWithLabel';
import { BrotherOwn, BrotherRent } from '../../../../Common/Icon';
import { useContextReports } from '../../../../../context/reports';
import { useReporRentVsOwnUpdate } from '../../../../../hooks/reports';

interface IRentVsOwn {
  provided?: DraggableProvided;
}

const RentVsOwn = ({ provided }: IRentVsOwn) => {
  const name = 'rentOrOwn';
  const { id: reportId } = useParams();
  const { report } = useContextReports()
  const updateValue = useReporRentVsOwnUpdate?.()
  const [price, setPrice] = useState<number>(Number(report?.rentVsOwnValue) || 800000);
  const [chartData, setChartData] = useState<ILineChartData>();
  const [data, setData] = useState<JsonResult>();
  const isMobileView = window.outerWidth < 768;

  const marks = {
    250000: { label: '$250,000', style: { transform: 'translateX(0)' } },
    ...(isMobileView ? {} : { 1250000: { label: '$1,250,000' } }),
    2000000: { label: '$2,000,000', style: { transform: 'translateX(-100%)' } },
  };

  useEffect(() => {
    
    setPrice(Number(report?.rentVsOwnValue));

  }, [report]);

  useEffect(() => {
    const labels = Array.from({ length: 15 }, (_, i) => moment().subtract(i, 'years').format('YYYY')).reverse();

    const dataByPrice = RENT_VS_OWN_CHART_DATA.find((item) => item.price === price);

    if (!dataByPrice) return;

    const newData: ILineChartData = {
      labels,
      datasets: RENT_VS_OWN_DATASETS.map((dataset) => ({
        label: dataset.label,
        data: dataByPrice[dataset.name],
        fill: {
          target: 'origin',
          above: `${dataset.color}10`,
        },
        backgroundColor: dataset.color,
        borderColor: dataset.color,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
      })),
    };

    setChartData(newData);
  }, [price]);

  useEffect(() => {
    const newData = RENT_VS_OWN_DATA.find((item) => item.price === price);

    if (newData) setData(newData);
  }, [price]);

  const debouncedUpdate = useCallback(debounce((value) => {
     
    updateValue.fetch({ rentVsOwnValue: `${value}` },
    reportId
    )
  }, 500), []);

  const onValueUpdate = (value: number) => {
    setPrice(value);
    debouncedUpdate(value); 
  }

  return (
    <ReportSection
      id={name}
      name={name}
      title='The Tale of Two Brothers: Rent vs Own'
      description="Imagine two brothers, both with the same savings balance. One rents a home, while
      the other buys a home. Let's follow their journey for 15 years and see how they 
      fare. Slide the bar to select a purchase price."
      provided={provided}
    >
      <div className='flex-col gap-20'>
        <Row style={{ padding: '20px' }} gutter={[20, 20]}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextWithLabel
              label='Total Time'
              value='15 Years'
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextWithLabel
              label='Savings Balance'
              value={`$${commafy(data?.startingSavingsBalance || 0)}`}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Slider
              style={{ width: '100%' }}
              className='slider-dots-less'
              min={250000}
              max={2000000}
              marks={marks}
              step={50000}
              tooltip={{ formatter: (value) => `$${commafy(value)}` }}
              trackStyle={{ background: 'var(--color-dark-blue)' }}
              handleStyle={{
                background: 'var(--color-light-light-gray)',
                border: 0,
                boxShadow: '0px 2px 4px -2px var(--color-box-shadow)',
              }}
              value={price}
              onChange={onValueUpdate}
            />
          </Col>
        </Row>
        <ContentCard padding={5}>
          <div style={{ overflowX: 'auto' }} className='flex-col gap-20'>
            <div style={{ minWidth: '1000px', marginTop: '20px' }}>
              {data && RENT_VS_OWN.map((row) => {
                if (row.id === 0) return (
                  <div key={row.id}>
                    <Row justify='start'
                      style={{
                        flex: '0 0 auto',
                        margin: '0 10px',
                        maxHeight: '88px',
                      }}>
                      <Col
                        span={4}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#F0F5FF'
                        }}
                      >
                        {null}
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#F0F5FF',
                          color: '#394255',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <Row style={{ flexWrap: 'nowrap' }}>
                          <Row
                            style={{
                              position: 'relative',
                              width: '96px',
                              height: '96px',
                              minWidth: '96px',
                            }}>
                            <BrotherRent
                              style={{
                                top: '-25px',
                                position: 'absolute',
                                borderRadius: '999px',
                                width: '96px',
                                height: '96px',
                                zIndex: '999'
                              }}
                              className='fs-2 color-dark-blue' />
                          </Row>
                          <Row align='middle' style={{ marginLeft: '15px' }}>
                            <div>
                              <Col span={24}>
                                <Typography.Title
                                  style={{ color: '#DE2626' }}>
                                  Renting
                                </Typography.Title>
                              </Col>
                              <Col span={24} style={{ marginBottom: '20px' }}>
                                <Typography.Text className='color-gray fs-0875 ws-nowrap'>BROTHER 1</Typography.Text>
                              </Col>
                            </div>
                          </Row>
                        </Row>
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#F0F5FF',
                          color: '#394255',
                          justifyContent: 'flex-start',
                        }}
                        className={clsx(
                          'flex-row',
                          'flex-align-center',
                          'flex-m-order-2',
                          'flex-m-justify-start',
                          'text-m-align-left'
                        )}
                      >
                        <Row style={{ flexWrap: 'nowrap' }} >
                          <Row style={{
                            position: 'relative',
                            width: '96px',
                            height: '96px',
                            minWidth: '96px',
                          }}>
                            <BrotherOwn
                              style={{
                                top: '-25px',
                                position: 'absolute',
                                width: '96px',
                                height: '96px',
                                borderRadius: '999px'
                              }}
                              className='fs-2 color-dark-blue' />
                          </Row>
                          <Row style={{ marginLeft: '15px' }}>
                            <div>
                              <Col span={24}>
                                <Typography.Title style={{ color: '#36AF51' }}>
                                  Owning
                                </Typography.Title>
                              </Col>
                              <Col span={24} style={{ marginBottom: '20px', marginLeft: '10px' }}>
                                <Typography.Text className='color-gray fs-0875 ws-nowrap'>BROTHER 2</Typography.Text>
                              </Col>
                            </div>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )

                return (
                  <div key={row.id} style={{ overflowX: 'auto' }}>
                    <Row justify='start' style={{ flex: '0 0 auto', margin: '0 10px' }}>
                      <Col
                        span={4}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#FAFCFF'
                        }}
                      >
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                          {row.icon && (
                            <Icon component={row.icon}
                              style={{
                                margin: '5px',
                                backgroundColor: '#FAFCFF',
                                width: '24px',
                                height: '24px'
                              }}
                              className='fs-2 color-dark-blue' />
                          )}
                          <Typography
                            style={{
                              fontSize: '12px',
                              lineHeight: '20px',
                              color: '#1E0C68',
                              fontWeight: '600',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}>
                            {row.text.toLocaleUpperCase()}
                          </Typography>
                        </div>
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#FAFCFF',
                          color: '#394255',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        {row.rent(data)}
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: '#FAFCFF',
                          color: '#394255',
                          justifyContent: 'flex-start',
                        }}
                        className={clsx(
                          'flex-row',
                          'flex-align-center',
                          'flex-m-order-2',
                          'flex-m-justify-start',
                          'text-m-align-left'
                        )}
                      >
                        {row.own(data)}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <Row style={{ minWidth: '1000px' }} align='middle' justify='center'>
              <Col>
                <Typography.Title level={2} className='color-dark-blue fs-2 ws-nowrap'>
                  15 Years Later
                </Typography.Title>
              </Col>
            </Row>
            <div className='m-b-20'>
              {data &&
                RENT_VS_OWN_RESULT.map((row) => (
                  <div key={row.id} style={{ minWidth: '1000px' }}>
                    <Row justify='center'>
                      <Col
                        span={4}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: `${row.id === 0 ? '#E5EEFF' : '#FAFCFF'}`
                        }}
                      >
                        <div
                          style={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                          {row.icon &&
                            <Icon component={row.icon} style={{
                              margin: '5px',
                              width: '24px',
                              height: '24px',
                              backgroundColor: `${row.id === 0 ? '#E5EEFF' : '#FAFCFF'}`
                            }}
                              className='fs-2 color-dark-blue' />
                          }
                          <Typography
                            style={{
                              fontSize: '12px',
                              lineHeight: '20px',
                              color: '#1E0C68',
                              font: 'Poppins',
                              fontWeight: '600',
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            {row.text.toLocaleUpperCase()}
                          </Typography>
                        </div>
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: `${row.id === 0 ? '#E5EEFF' : '#FAFCFF'}`,
                          color: '#394255',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          fontWeight: `${row.id === 0 ? '600' : '400'}`
                        }}
                      >
                        {row.rent(data)}
                      </Col>
                      <Col
                        span={10}
                        style={{
                          border: '1px solid #F0F5FF',
                          padding: '10px',
                          backgroundColor: `${row.id === 0 ? '#E5EEFF' : '#FAFCFF'}`,
                          color: '#394255',
                          justifyContent: 'flex-start',
                          fontWeight: `${row.id === 0 ? '600' : '400'}`
                        }}
                        className={clsx(
                          'flex-row',
                          'flex-align-center',
                          'flex-m-order-2',
                          'flex-m-justify-start',
                          'text-m-align-left'
                        )}
                      >
                        {row.own(data)}
                      </Col>
                    </Row>
                  </div>
                ))}
            </div>
            <div style={{ padding: '10px', minWidth: '1000px' }}>
              <LineChart id='chartVsOwn' data={chartData} />
            </div>
          </div>
        </ContentCard>
      </div >
    </ReportSection >
  );
};

RentVsOwn.defaultProps = {
  provided: undefined,
};

export default RentVsOwn;
