import { Col, Row, Typography } from 'antd';
import moment from 'moment-timezone';
import { IReportLoan } from '../../../../../../hooks/reports';
import { commafy } from '../../../../../../utils/text';
import { IBuyDownRow } from '../../../types';

import styles from './index.module.less';

interface IOption {
  title: string;
  subtitle: string;
  rows: IBuyDownRow[];
  loan?: IReportLoan;
}

const Option = ({ title, subtitle, rows, loan }: IOption) => (
  <div className={styles.option}>
    <div className={styles.headerRow}
      style={{
        padding:"8px 15px",
        backgroundColor: "#E5EEFF", display: 'flex',
        flexDirection: "row", justifyContent: 'space-between', alignItems: "center"
      }}>
      <div className={styles.cell}>
        <Typography.Text className={styles.title} style={{ color: '#394255' }}>{title}</Typography.Text>
      </div>
      <div className={styles.cell} style={{ maxWidth: "90px" }}>
        <Typography.Text className={styles.subtitle} style={{ color: '#394255' }}>
          {subtitle}
        </Typography.Text>
      </div>
    </div>
    {rows.map((row, index) => (
      <Row key={row.key} className={styles.row}>
        <Col span={9}>
          <Typography.Text className={styles.cellText}>{moment().year() + index}</Typography.Text>
        </Col>
        <Col span={9}>
          <Typography.Text className={styles.cellText}>{row.interestRate}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text className={styles.cellText}>${commafy(row?.paymentWithSubstitudy || '')}</Typography.Text>
        </Col>
      </Row>
    ))}
    <Row className={styles.row}>
      <Col span={9}>
        <Typography.Text className={styles.cellText}>
          {moment().add(rows.length, 'years').year()} - {moment().add(loan?.loanPeriodInYears, 'years').year()}
        </Typography.Text>
      </Col>
      <Col span={9}>
        <Typography.Text className={styles.cellText}>{loan?.interestRate || 0}%</Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text className={styles.cellText}>
          ${rows[0].payment === 'NaN' ? '0' : commafy(rows[0].payment)}
        </Typography.Text>
      </Col>
    </Row>
  </div>
);

Option.defaultProps = {
  loan: undefined,
};

export default Option;
