import { Col, Form, Row, Select, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import MovingTo from '../../../../Common/MovingTo';
import { useContextReports } from '../../../../../context/reports';
import {
  useReportTaxAdvantage,
  useMortgageInterest,
  IReportMortgageIntrerest,
  IReportLoan,
} from '../../../../../hooks/reports';
import { JsonResult } from '../../../../../types';
import { commafy } from '../../../../../utils/text';
import CustomInput from '../../../../Common/CustomInput';
import TextWithLabel from '../../../../Common/TextWithLabel';
import { FEDERAL_TAX_RATE, FederalTaxRate, FILING_STATUS } from '../../constants';
import ReportSection from '../Section';

interface ITaxAdvantage {
  provided?: DraggableProvided;
}

const statuses = ['single', 'fillingSeparately', 'fillingJointly', 'head'];

const TaxAdvantage = ({ provided }: ITaxAdvantage) => {
  const name = 'taxAdvantage';
  const form = Form.useFormInstance();
  const { handleLoansUpdate, loans: reportLoans } = useContextReports();
  const reportTaxAdvantage = useReportTaxAdvantage();
  const mortInterestValue = useMortgageInterest();
  const [loanKey, setLoanKey] = useState<number>(0);
  const [formData, setFormData] = useState<JsonResult>();
  const movingTo = useWatch(['movingTo', name], form);
  const loans = useWatch('loans', form);
  const isSectionActive = useWatch(['reportView', 'taxAdvantage', 'active'], form);
  // const formFillingStatus = useWatch(['loans', loanKey, 'fillingStatus'], form) as FederalTaxRate;
  const formFillingStatusInitial = useWatch(['loans', 0, 'fillingStatus'], form) as FederalTaxRate;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      !movingTo ||
      // !loans?.[loanKey]?.id ||
      !loans) return;

    const index = loans.findIndex((item: IReportLoan) => item.id === movingTo);

    if (index >= 0) {
      setLoanKey(index);

      if (!form.getFieldValue(['loans', loanKey])) return

      if (form.getFieldValue(['loans', loanKey])) {
        mortInterestValue.fetch({ id: loans[loanKey].id }).then((response) => {
          const fetchedInterest = (response as IReportMortgageIntrerest)?.data?.mortgageInterest;

          if (fetchedInterest) {
            form.setFieldValue(['loans', loanKey, 'mortgageInterest'], fetchedInterest.toString());
          }
        })
      };
    }
  }, [movingTo, reportLoans, loans, loanKey]);

  useEffect(() => {
    if (!isSectionActive && !loans) return;

    handleChange();
  }, [isSectionActive, loanKey, loans, loans?.length, movingTo]);

  useEffect(() => {
    if (
      // !movingTo ||
      !isSectionActive ||
      !formData ||
      Object.values(formData).some((data) => data === undefined || data === null)
    )
      return;

    if (!form.getFieldValue(['loans', loanKey])) return

    const { realEstateTaxes, otherDeductions, mortgageInterest } = form.getFieldValue(['loans', loanKey]) || '0';
    const { fillingStatus, taxRate, stateTaxRate } = form.getFieldValue(['loans', 0]);

    if (!statuses.includes(fillingStatus)) {
      const stringFillingStatus = FILING_STATUS.find((item) => item.price === Number(fillingStatus))?.value

      form.setFieldValue(['loans', 0, 'fillingStatus'], stringFillingStatus);
    }

    const updatedLoan = {
      fillingStatus: FILING_STATUS.find((item) => item.value === fillingStatus)?.price ?? fillingStatus,
      taxRate,
      stateTaxRate,
      realEstateTaxes,
      otherDeductions,
      mortgageInterest
    };

    const delay = 850;
    const timeoutId = setTimeout(() => {
      reportTaxAdvantage.fetch({ id: loans[loanKey].id, ...updatedLoan })
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);

  }, [formData, movingTo, isSectionActive, loans, loans?.length, loanKey]);

  const handleChange = () => {
    if (form.getFieldValue(['loans', loanKey])) {
      const { realEstateTaxes, otherDeductions, mortgageInterest }
        = form.getFieldValue(['loans', loanKey]) || '0';
      const { fillingStatus, taxRate, stateTaxRate } = form.getFieldValue(['loans', 0]);

      const realEstateTaxesValue = (realEstateTaxes &&
        parseFloat(realEstateTaxes.toString()?.replace(/,/g, ''))) || 0;
      const mortgageInterestValue = (mortgageInterest &&
        parseFloat(mortgageInterest.toString()?.replace(/,/g, ''))) || 0;

      setFormData({
        fillingStatus: FILING_STATUS.find((item) => item.value === fillingStatus)?.price ?? fillingStatus,
        taxRate,
        stateTaxRate,
        realEstateTaxes: realEstateTaxesValue,
        otherDeductions,
        mortgageInterest: mortgageInterestValue,
      });
    }
  };

  const handleInputBlur = () => {
    handleLoansUpdate?.();
    handleChange();
  };

  return (
    <ReportSection
      name={name}
      id={name}
      title='Income Tax Advantage of Homeownership'
      description='Here is an estimation of the total income tax savings you could receive through homeownership.
       Please consult with a tax professional for more detailed information.'
      provided={provided}
    >
      <div className='flex-col gap-20 tax-advantage' style={{ padding: '10px' }}>
        <Row gutter={windowWidth >= 768 ? [20, 20] : [0, 0]} style={{ padding: '8px' }}>
          <Col xl={12} span={24}>
            <Space size={72}>
              <TextWithLabel
                label='Tax benefit results'
                value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerMonth || 0)}`}
                interval='per month'
              />
              {windowWidth >= 768 ? (
                <TextWithLabel
                  value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerYear || 0)}`}
                  interval='per year'
                />
              ) : null}
            </Space>
          </Col>
          <MovingTo name={name} />
          {windowWidth < 768 ? (
            <Col xl={12} span={24}>
              <TextWithLabel
                value={`$${commafy(reportTaxAdvantage.data?.data.taxBenefitPerYear || 0)}`}
                interval='per year'
              />
              <MovingTo name={name} />
            </Col>
          ) : null}
        </Row>
        <Row gutter={[20, 20]} style={{ backgroundColor: '#E5EEFF', padding: '15px', borderRadius: '8px' }}>
          <Col xl={8} span={24}>
            <Form.Item
              label='Filing Status'
              name={['loans', 0, 'fillingStatus']}
              className='label-gray m-0 w-100'
            >
              <Select
                size='large'
                popupClassName='select-popup'
                placement='bottomRight'
                optionLabelProp='text'
                options={FILING_STATUS}
                onSelect={handleInputBlur}
              />
            </Form.Item>
          </Col>
          <Col xl={8} span={24}>
            <Form.Item label='Federal Tax Rate' name={['loans', 0, 'taxRate']} className='label-gray m-0 w-100'>
              <Select
                size='large'
                popupClassName='select-popup'
                placement='bottomRight'
                optionLabelProp='text'
                options={FEDERAL_TAX_RATE[formFillingStatusInitial || 'single']?.map((item) => ({
                  value: item.value,
                  label: (
                    <div className='flex-row flex-justify-space-between'>
                      <Typography>{item.value}%</Typography>
                      <Typography>{item.taxBrackets}</Typography>
                    </div>
                  ),
                  text: item.text,
                }))}
                onSelect={handleInputBlur}
              />
            </Form.Item>
          </Col>
          <Col xl={8} span={24}>
            <CustomInput
              inputClassName='bg-white'
              formItemProps={{
                className: 'm-0 label-gray',
                label: 'State Tax Rate',
                name: ['loans', 0, 'stateTaxRate'],
              }}
              identifier={['loans', 0, 'stateTaxRate']}
              bordered
              suffix='%'
              placeholder='0%'
              onBlur={handleInputBlur}
            />
          </Col>
          <Col xl={8} span={24}>
            <CustomInput
              inputClassName='bg-white'
              formItemProps={{
                className: 'm-0 label-gray',
                label: 'Property Tax',
                name: ['loans', loanKey, 'realEstateTaxes'],
              }}
              identifier={['loans', loanKey, 'realEstateTaxes']}
              bordered
              prefix='$'
              placeholder='$0'
            />
          </Col>
          <Col xl={8} span={24}>
            <CustomInput
              inputClassName='bg-white'
              formItemProps={{
                className: 'm-0 label-gray',
                label: 'Mortgage Interest',
                name: ['loans', loanKey, 'mortgageInterest'],
              }}
              identifier={['loans', loanKey, 'mortgageInterest']}
              bordered
              prefix='$'
              placeholder='$0'
            />
          </Col>
          <Col xl={8} span={24}>
            <CustomInput
              inputClassName='bg-white'
              formItemProps={{
                className: 'm-0 label-gray',
                label: 'Other Deductions',
                name: ['loans', loanKey, 'otherDeductions'],
              }}
              identifier={['loans', loanKey, 'otherDeductions']}
              bordered
              prefix='$'
              placeholder='$0'
            />
          </Col>
        </Row>
      </div>
    </ReportSection>
  );
};

TaxAdvantage.defaultProps = {
  provided: undefined,
};

export default TaxAdvantage;
