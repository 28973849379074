import Icon from '@ant-design/icons';
import { Button, Col, Row, Space, Steps, StepsProps, Typography } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReportId } from '../../../../../hooks/reports';
import { Back } from '../../../../Common/Icon';
import Loading from '../../../../Common/Loading';
import ReportRow from './activityRow';

const customDot: StepsProps['progressDot'] = (dot) => dot;

const ActivitiesById = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, fetch, loading } = useReportId();

  useEffect(() => {
    if (!id) return

    fetch(undefined, id)

  }, []);

  const steps = data?.data ? [{
    status: 'finish' as 'wait' | 'process' | 'finish' | 'error',
    description: (
      <div style={{ paddingTop: '2rem', maxWidth: '230px' }}>
        <ReportRow report={data?.data} />
      </div>
    ),
    className: 'steps'
  }] : [{
    status: 'wait' as 'wait' | 'process' | 'finish' | 'error',
    className: 'default-class',
    description: <div>No activities found</div>
  }];

  const handleBack = () => {
    navigate({ pathname: '/reports' });
  };

  return (
    <section>
      <Loading visible={loading} absolute />
      {data &&
        <Row>
          <Col span={24}>
            <Space direction="vertical" size={24}>
              <Space size={4}>
                <Button
                  type="ghost"
                  icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />}
                  onClick={handleBack}
                />
                <Typography.Title style={{ marginLeft: "20px" }}>
                  Activities for report {data.data?.name.toLocaleUpperCase() || "(Unnamed Report)"}
                </Typography.Title>
              </Space>
              <Steps
                style={{ marginLeft: "20px" }}
                progressDot={customDot}
                direction="vertical"
                items={[...(steps || []), { status: 'finish', className: 'd-none' }]} />
            </Space>
          </Col>
        </Row>
      }
    </section>
  );
};

export default ActivitiesById;
