import { Button, Space, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JsonResult, ModalState, ModalTypes } from '../../../types';
import GetQuoteModal from './GetQuote/GetQuoteModal';
import ReportsTable from './Table';
import ShareModal from './ShareModal';
import { useReportCreate, useReportTrackActivities } from '../../../hooks/reports';
import Loading from '../../Common/Loading';

const Reports = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)
  const [modal, setModal] = useState<ModalState | null>(null);
  const reportCreate = useReportCreate();
  const reportTrackActivities = useReportTrackActivities();
  const closeModal = useCallback(() => setModal(null), [setModal]);

  const handleReportGenerate = () => {
    setLoading(true)
    const loanColor: JsonResult = {};

    reportCreate.fetch({ name: '', status: 'draft', loanColor }).then((res) => {
      if (!res?.data.id) return;

      reportTrackActivities.fetch({ id: res.data.id, type: 'created' }).then(() => {
        navigate(`/reports/${res.data.id}/edit`)
      });
    }).finally(() => setLoading(false));
  };

  return (
    <Space direction="vertical" size={12}>
      <Loading visible={loading} absolute />
      <div className="table-header">
        <Typography.Title level={2} style={{color:"#464646"}}>Report Builder</Typography.Title>
        <Space>
          <Button type="primary" size="large" onClick={handleReportGenerate}>
            Create Report
          </Button>
        </Space>
      </div>
      <GetQuoteModal open={modal?.type === ModalTypes.getQuote} onCancel={closeModal} onOk={modal?.onOk} />
      <ShareModal open={modal?.type === ModalTypes.share} onCancel={closeModal}
        afterClose={modal?.afterClose} onOk={modal?.onOk} data={modal?.data} />
      <ReportsTable />
    </Space>
  );
};

export default Reports;
