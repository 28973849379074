import { ILoanAdvancedValue, ILoanValue } from '../types/reports';

export const prepareLoanDataFromForm = (columns: ILoanValue[], fields?: string[]) =>
  columns?.map((column) => {
    const loan: ILoanValue = {};

    Object.entries(column).forEach(([key, item]) => {
      if (fields?.length && !fields.some((field) => field === key)) return;

      const advancedItem = item as ILoanAdvancedValue;

      if (key === 'color') {
        loan[key] = item;

        return;
      }

      if (item && Object.keys(item).includes('value') && !advancedItem.value) {
        loan[key] = null;

        return;
      }

      if (typeof item === 'string' || typeof item === 'number') {
        loan[key] = item.toString();

        return;
      }

      if (advancedItem?.value) {
        loan[key] = advancedItem?.value ? `${advancedItem.value}`.replace(/,/g, '') : null;

        return;
      }

      loan[key] = item || undefined;
    });

    return loan;
  });
