/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Icon from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Typography, Popover } from 'antd';
import Form from 'antd/es/form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContextReports } from '../../../../../context/reports';
import { Back, Info } from '../../../../Common/Icon';
import { ROWS } from '../../constants';
import WelcomeText from '../WelcomeText';
import Customize from './Customize';
import ReportForm from './Form';
import styles from './index.module.less';

interface ILoanComparisons {
  headerLess?: boolean;
  disabled?: boolean;
  headerActions?: React.ReactNode;
}

const LoanComparisons = ({ headerLess, disabled, headerActions }: ILoanComparisons) => {
  const navigate = useNavigate();
  const form = useFormInstance();
  const { report, isBorrower, settings, handleReportUpdate } = useContextReports();

  const namePath = ['report', 'name'];
  const errors = form.getFieldError(namePath);
  const hasError = errors.length > 0;

  useEffect(() => handleFocus(), [form, report])

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const currentPath = window.location.pathname;

    if (currentDomain === 'mortgage-report.app') {
      if (!currentPath.includes('/report')) {
        window.location.href = 'https://mortgagemaker.ai';
      }
    }

    if (!settings) {
      ROWS.forEach((row) => {
        form.setFieldValue(['tableView', row.name], row);
      });
    }

    if (report?.name) {
      form.setFieldsValue({ report: { name: report.name } });
    }
  }, [settings, report, form]);

  const handleBack = () => {
    navigate('/reports');
  };

  const handleFocus = () => {
    const value = form.getFieldValue(namePath);

    if (!value) {
      form.setFields([
        {
          name: namePath,
          // @ts-ignore
          errors: [<div style={{ fontSize: '22px' }}>To start, please enter a report name</div>],
        },
      ]);
    } else {
      form.setFields([
        {
          name: namePath,
          // @ts-ignore
          errors: [],
        },
      ]);
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.trim() !== '') {
      form.setFields([
        {
          name: namePath,
          errors: [],
        },
      ]);
    } else {
      form.setFields([
        {
          name: namePath,
          // @ts-ignore
          errors: [<div style={{ fontSize: '22px' }}>To start, please enter a report name</div>],
        },
      ]);
    }
  }

  const details = <Typography className="fs-0875">
    Enter a report title above, and your work will auto-save as you go. Drag the
    hashmark icon to rearrange columns, click the copy icon to clone columns, click the
    column header to rename it, click the paint icon to highlight, or click the customize
    button to turn fields on/off or organize them.  Drag entire sections further below to
    quickly reorganize what matters most to your client.
    <br />
  </Typography>

  return (
    <div className="flex-col" style={{ gap: "5px" }}>
      <Form.Item name="tableView" noStyle />
      {!headerLess && (
        <div className="flex-row flex-align-center gap-4">
          <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
          <Form.Item name={['report', 'name']}
            className={`table-form-item flex-1 ${hasError ? '' : ''}`}>
            <Input
              className="title-input"
              placeholder="Enter Your Client's Name as Report Title"
              onBlur={() => handleReportUpdate?.()}
              autoFocus
              onFocus={handleFocus}
              name="name"
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
      )}
      {(!isBorrower || (isBorrower && report?.welcome_message)) && <WelcomeText />}

      {!headerLess && (
        <div style={{ padding: "15px", backgroundColor: "#1E0C68", borderRadius: "12px" }}>
          <Row gutter={[20, 20]}>
            <Col span={24} lg={18}>
              <Space size={4} direction="vertical">
                <Row style={{ gap: '10px' }}>
                  <Typography.Title level={2}>Loan Comparisons</Typography.Title>
                  <Popover content={<div style={{
                    maxWidth: '450px',
                    zIndex: '1',
                    transition: '.2s ease-in-out'
                  }}>{details}</div>}>
                    <Icon component={Info} style={{
                      color: "white",
                      fontSize: '1.14rem',
                      cursor: 'help'
                    }} />
                  </Popover>
                </Row>
                <Typography style={{ color: "#A5B5D4" }} className="fs-0875">
                  Use Tab to move to the next field, Tab + Shift to move to the previous field, Control
                  + Arrow to move left or right.
                </Typography>
              </Space>
            </Col>
            <Col span={24} lg={6}>
              <div className={styles.customizeWrapper}>
                {headerActions}
                {settings && <Customize />}
              </div>
            </Col>
          </Row>
        </div>
      )}
      {headerLess && headerActions && <div className="flex-row flex-justify-end gap-8">{headerActions}</div>}
      <ReportForm disabled={disabled} />
    </div>
  );
};

LoanComparisons.defaultProps = {
  headerLess: false,
  disabled: false,
  headerActions: undefined,
};

export default LoanComparisons;
