/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Collapse, Form, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useContextReports } from '../../../../../context/reports';
import { useContextUsers } from '../../../../../context/users';
import ContentCard from '../../../../Common/ContentCard';

import 'react-quill/dist/quill.snow.css';
import './style.less';

// const { Panel } = Collapse;

export const editorModules = {
  toolbar: [
    [{ 'size': [] }],
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
  ]
};

export const editorModuleReadOnly = {
  toolbar: false
};

const WelcomeText = () => {
  const form = useFormInstance();
  const { profile } = useContextUsers();
  const { report, isBorrower, handleReportUpdate } = useContextReports();
  const [isDisabled, setIsDesabled] = useState<boolean>(true)
  const [htmlContent, setHtmlContent] = useState<string | null | undefined>()

  useEffect(() => {
    if (!profile && !report) return;
    setHtmlContent(report?.welcome_message)
    setIsDesabled(false)

    if (report?.name) {
      setIsDesabled(false)
    }
  }, [profile, report]);

  const handleEditorChange = (content: string) => {
    setHtmlContent(content);
    form.setFieldValue(['report', 'welcome_message'], content);
  };


  if (isBorrower && htmlContent === '<p><br></p>') {
    return null
  }

  return (
    <ContentCard  padding={10}>
      <div id='welcomeMessage' className="flex-col gap-8" style={{ border: "1px solid #F0F5FF" }}>
        {!isBorrower && (
          <div className="flex-col"
            style={{ backgroundColor: "#1E0C68", padding: "10px", borderRadius: "12px" }}>
            <Typography.Title level={2}>Welcome Message</Typography.Title>
            <Typography.Text className="color-gray">
              Leave blank to hide from borrower view.
            </Typography.Text>
          </div>
        )}
        {isBorrower ? (
          <div className='custom-quill-editor'>
            <ReactQuill theme="snow"
              value={htmlContent || ""}
              modules={editorModuleReadOnly}
              readOnly />
          </div>
        ) : (
          <Form.Item name={['report', 'welcome_message']} label={!isBorrower ? 'Enter Text' : undefined} noStyle>
            <div className='custom-quill-editor'>
              <ReactQuill theme="snow"
                value={htmlContent || ""}
                modules={editorModules}
                readOnly={isDisabled}
                onChange={handleEditorChange}
                onBlur={() => handleReportUpdate?.()}
                placeholder="Enter script text" />
            </div>
          </Form.Item>
        )}
      </div>
    </ContentCard>
  );
};

WelcomeText.defaultProps = {
  provided: undefined,
};

export default WelcomeText;
