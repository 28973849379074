import { Col, DatePicker, Form, Row, Select, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import { IReportLoan } from '../../../../../hooks/reports';
import { required } from '../../../../../utils/inputRules';
import LinearCalendar from '../../../../Common/LinearCalendar';
import MovingTo from '../../../../Common/MovingTo';
import TextWithLabel from '../../../../Common/TextWithLabel';
import { HOW_LONG_TO_BOY_EVENTS } from '../../constants';
import { IEvent } from '../../types';
import ReportSection from '../Section';

interface IHowLongToBuy {
  provided?: DraggableProvided;
}

const HowLongToBuy = ({ provided }: IHowLongToBuy) => {
  const name = 'howLongTakeToBuy';
  const form = useFormInstance();
  const movingTo = useWatch(['movingTo', name], form);
  const loans = useWatch('loans', form);
  const [loanKey, setLoanKey] = useState<number>(0);
  const [date, setDate] = useState<Moment | null>(null);
  const [events, setEvents] = useState<IEvent[]>(HOW_LONG_TO_BOY_EVENTS);
  const options = Array.from({ length: 60 }, (_, i) => ({ label: i + 1, value: (i + 1).toString() }));
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const { isBorrower, handleLoansUpdate } = useContextReports();

  const updateEvents = (newEvents: IEvent[]) => {
    const updatedEvents = newEvents.map((event, idx) => ({
      ...event,
      day: newEvents
        .reduce(
          (previousValue, currentValue, currentIndex) =>
            currentIndex < idx ? previousValue + Number(currentValue.duration) : previousValue,
          1
        )
        .toString(),
    }));

    setEvents(updatedEvents);
  };

  useEffect(() => {
    if (
      // !movingTo || 
      !loans) return;

    let index = loans.findIndex((loan: IReportLoan) => loan.id === movingTo || 0);

    index = index < 0 ? index = 0 : index

    if (index >= 0 && index !== loanKey) {
      setLoanKey(index);
    }
  }, [movingTo, loans]);

  useEffect(() => {
    if (!loans || loanKey < 0) return;

    const newEvents = HOW_LONG_TO_BOY_EVENTS.map((event) => (
      {
        ...event,
        duration: loans[0][event.name],
      }));

    const { startAt } = loans[loanKey];

    setDate(startAt ? moment(startAt) : null);
    updateEvents(newEvents);
  }, [loanKey, loans]);

  const handleDateChange = (value: Moment | null) => {
    setDate(value);

    form.setFieldValue(['loans', loanKey, 'startAt'], value?.format('YYYY-MM-DD HH:mm:ss'));
    handleLoansUpdate?.();
  };

  const updateEventsRange = () => {
    if (!date || !events) return;

    const newEvents = events.map((event) => {
      const startDate = moment(date).add(Number(event.day) - 1, 'd');
      const endDate = moment(date).add(Number(event.day) - 1 + Number(event.duration), 'd');
      const diffDays = endDate.diff(startDate, 'days');

      return {
        ...event,
        range:
          diffDays > 1
            ? `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`
            : startDate.format('MMM DD, YYYY'),
      };
    });

    if (JSON.stringify(newEvents) === JSON.stringify(events)) return;

    setEvents(newEvents);
  };

  useEffect(() => {
    updateEventsRange();
  }, [events, date]);

  const handleChange = (value: string, eventName: string) => {
    const newEvents = [...events.sort((a, b) => a.key - b.key)];
    const index = newEvents.findIndex((event) => event.name === eventName);
    const updatedEvent = {
      ...newEvents[index],
      duration: value,
    };

    newEvents.splice(index, 1, updatedEvent);

    updateEvents(newEvents);
  };

  const maxDays = events.reduce((previousValue, currentValue) => previousValue + Number(currentValue.duration), 0);

  return (
    <ReportSection
      name={name}
      title="Homebuying Timeline"
      description="In this section, you can see an average
       timeline of how long it takes to purchase a home in your market."
      provided={provided}
    >
      <div style={{padding:"10px"}} className="flex-col gap-20">
        <Row>
          <Col xl={12} span={24}>
            <TextWithLabel label="Total days" value={`${maxDays || 0} days`} />
          </Col>
          <Col xl={12} span={24}>
            <div className="w-100 flex-row flex-justify-end gap-12 flex-m-col">
              {!isBorrower && (
                <Form.Item
                  label="Start at"
                  className="label-gray m-0 cursor-pointer w-m-100"
                  rules={isSectionActive && required('date')}
                >
                  <DatePicker
                    className="w-m-100"
                    value={date}
                    size="large"
                    format="MMM DD, YYYY"
                    showNow
                    onChange={handleDateChange}
                  />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
        <div className="flex-row flex-wrap gap-20">
          {events.map((event) => (
            <div key={event.key} className="flex-col gap-8 w-max-40 w-m-max-none" style={{ width: '30%' }} >
              {!isBorrower && (
                <Form.Item
                  name={['loans', loanKey, event.name]}
                  className="label-gray m-0 cursor-pointer"
                  style={{ width: 100 }}
                >
                  <Select
                    size="large"
                    options={options}
                    onChange={(value) => handleChange(value, event.name)}
                    onSelect={() => handleLoansUpdate?.()}
                    defaultValue={
                      loans?.[loanKey]?.[event.name]
                    }
                  />
                </Form.Item>
              )}
              <Space size={4}>
                <div style={{ width: 8, height: 8, borderRadius: '50%', background: event.color }} />
                <Typography.Title level={4}>{isBorrower ? `${event.duration} days ` : null}
                  {event.label}</Typography.Title>
              </Space>
              <Typography.Text className="fs-0875 color-gray">{event.description}</Typography.Text>
            </div>
          ))}
        </div>
        <div style={{ overflowX: 'auto', overflowY: "hidden" }}>
          <div style={{ width: "1000px" }} >
            <LinearCalendar
              eventType="advanced"
              maxDays={maxDays > 20 ? maxDays + 6 : 20}
              events={events.filter((event) => event.duration)}
              step={maxDays > 20 ? 2 : undefined}
            />
          </div>
        </div>
      </div>
    </ReportSection>
  );
};

HowLongToBuy.defaultProps = {
  provided: undefined,
};

export default HowLongToBuy;
