import { Button, Space, Typography } from 'antd';
import { useState } from 'react';
import Stripe from 'stripe';
import Icon from '@ant-design/icons';
import ContentCard from '../../ContentCard';

import styles from './index.module.less';
import ConfirmModal from '../confirmModal';
import { FEATURES } from '../../../Pages/Reports/constants';
import { Checked, Close } from '../../Icon';

interface IPlanItem {
  plan: Stripe.Plan;
  active?: boolean;
  handleChange: (id: string) => void;
  features: string[];
  buttonLabel?: string;
  loading?: boolean;
}

export const formatPlanData = (plan: Stripe.Plan) => {
  if (plan.amount && !plan.trial_period_days) {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(plan.amount / 100);

    const interval = plan.interval?.slice(0, 2) === 'ye' ? 'yr' : plan.interval?.slice(0, 2);

    return `${formattedAmount}/${interval}`;
  }

  return `$0/${plan.interval?.slice(0, 2)}`;
}

const PlanItem = ({ plan, active, handleChange, buttonLabel, loading }: IPlanItem) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { metadata } = plan;

  const toggleModal = () => setIsOpen(!isOpen)

  const renderFeature = (key: string) => metadata && metadata[key] === 'true' ? (
    <Icon component={Checked} className="color-blue fs-1" />
  ) : (
    <Icon component={Close} className="color-red fs-1" />
  );

  return (
    <ContentCard bordered padding={24} height="100%" background={active ? 'var(--color-border)' : 'transparent'}>
      <ConfirmModal isOpen={isOpen} handleChange={handleChange} id={plan.id} toggleModal={toggleModal} />
      <div className={styles.content}>
        <Space direction="vertical" size={12}>
          <Typography.Title level={3} className={styles.name}>
            {plan.nickname}
          </Typography.Title>
          {plan.metadata &&
            Object.entries(plan.metadata)
              .filter(([key]) => key.includes('description'))
              .map(([key, value]) => (
                <Typography.Text key={key} className="color-gray">
                  {value}
                </Typography.Text>
              ))}
        </Space>
        <Typography.Title>
          {formatPlanData(plan)}
        </Typography.Title>
        <Space direction="vertical" size={20} className={styles.features}>
          {Object.keys(FEATURES).map(key => (
            <div key={key} className="flex-row gap-12 flex-align-center">
              {renderFeature(key)}
              <Typography.Text className="fs-0875 color-gray">
                {FEATURES[key]}
              </Typography.Text>
            </div>
          ))}
        </Space>
        <div>
          <Button
            block={false}
            className="btn-default-dark-blue"
            type="default"
            size="large"
            onClick={() => toggleModal()}
            loading={active && loading}
            disabled={active || (!active && loading)}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </ContentCard>
  );
}

PlanItem.defaultProps = {
  active: false,
  buttonLabel: 'Upgrade',
  loading: false,
};

export default PlanItem;
