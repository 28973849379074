import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Space, Typography } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatDateDayDate } from '../../../../../utils/date';
import ContentCard from '../../../../Common/ContentCard';

interface ITag {
  icon: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
  label: string;
  date: string;
  url?: string;
}
const Tag = ({ icon, label, date, url }: ITag) => (
  <ContentCard padding="4px 8px" backgroundColor="var(--color-border)" minWidth='190px'>
    <Space direction="vertical" size={8}>
      <div className="flex-row flex-align-center gap-8">
        <Icon component={icon} className="fs-125 color-dark" />
        {url ? (
          <Link to={url}>
            <Typography.Text className="capitalize ws-nowrap color-blue">{label}</Typography.Text>
          </Link>
        ) : (
          <Typography.Text className="capitalize ws-nowrap">{label}</Typography.Text>
        )}
      </div>
      <Typography.Text className="color-gray fs-075 ws-nowrap">{formatDateDayDate(date)}</Typography.Text>
    </Space>
  </ContentCard>
);

Tag.defaultProps = {
  url: undefined,
};

export default Tag;
