/* eslint-disable no-nested-ternary */
import { Typography } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ICurrentMarketRates } from '../../../hooks/reports';
import ContentCard from '../ContentCard';

interface IMarketRatesChart {
  title: string;
  data?: ICurrentMarketRates[];
  loading: boolean;
  height: number | string;
  reversed?: boolean;
  controls?: React.ReactNode;
}

const MarketRatesChart = ({ title, data, loading, height, reversed, controls }: IMarketRatesChart) => {
  const [chartData, setChartData] = useState<ApexAxisChartSeries>();
  const colorRed = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-red');
  const colorGreen = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-green');

  useEffect(() => {
    if (!data) return;

    const newData = [
      {
        data: data
          .filter((item) => moment().diff(moment(item.d), 'months') <= 3)
          .map((item) => ({
            x: moment(item.d),
            y: [item.o.toFixed(3), item.h.toFixed(3), item.l.toFixed(3), item.c.toFixed(3)],
          })),
      },
    ];

    setChartData(newData);
  }, [data]);

  const getDifference = (value?: number, prevValue?: number) => {
    if (!value || !prevValue) return { diff: 0, color: 'inherit' };

    const diff = value - prevValue;
    const color = reversed ? (diff < 0 ? 'green' : 'red') : (diff > 0 ? 'green' : 'red');

    return {
      diff: diff > 0 ? `+${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 6.0% Coupon' ? 2 : 3)}`
        : `-${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 6.0% Coupon' ? 2 : 3)}`,
      color
    };
  };

  const options = {
    chart: {
      zoom: { enabled: true },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: reversed ? colorRed : colorGreen,
          downward: reversed ? colorGreen : colorRed,
        },
      },
    },
    xaxis: {
      type: 'category' as 'category' | 'datetime' | 'numeric',
      tickAmount: 9,
      labels: {
        formatter(val: string) {
          return moment(val).format('MMM DD')
        }
      }
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <ContentCard bordered padding={20}>
      <div className="flex-col gap-12">
        <div className="flex-col gap-4">
          <div className="flex-row gap-20 flex-align-center flex-justify-space-between">
            <div className="flex-row gap-20">
              <Typography.Title level={2} style={{color:"#464646"}}>{title}</Typography.Title>
              {data?.[0].c && data?.[1].c && (
                <div className="flex-row gap-4 flex-align-center">
                  <Typography.Text className="color-dark fs-1">{data[data.length - 1].c
                    .toFixed(title === 'UMBS 30 Year - 6.0% Coupon' ? 2 : 3)}</Typography.Text>
                  <Typography.Text
                    style={{
                      color: reversed ? (Number(getDifference(data?.[data.length - 1].c,
                        data?.[data.length - 1].o).diff) < 0 ? 'green' : 'red')
                        : (Number(getDifference(data?.[data.length - 1].c,
                          data?.[data.length - 1].o).diff) > 0 ? 'green' : 'red')
                    }}
                  >
                    ({getDifference(data?.[data.length - 1].c, data?.[data.length - 2].c).diff
                    })
                  </Typography.Text>
                </div>
              )}
            </div>
            {controls}
          </div>
          {data && (
            <div className="flex-row gap-12">
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Open: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].o.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">High: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].h.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Low: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].l.toFixed(3)}</Typography.Text>
              </div>
              <div className="flex-row gap-4">
                <Typography.Text className="color-gray uppercase">Close: </Typography.Text>
                <Typography.Text className="color-gray">{data[data.length - 1].c.toFixed(3)}</Typography.Text>
              </div>
            </div>
          )}
        </div>
        <ContentCard loading={loading && !chartData}>
          <div style={{ margin: '0 -20px -20px' }}>
            {chartData && <Chart height={height}
              options={options} series={chartData} type="candlestick" />}
          </div>
        </ContentCard>
      </div>
    </ContentCard>
  );
};

MarketRatesChart.defaultProps = {
  data: undefined,
  reversed: false,
  controls: undefined,
};

export default MarketRatesChart;
