import React from 'react';
import { Input, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { debounce } from 'lodash';

interface ColumnSearchProps<T> {
  dataIndex: keyof T;
  indexName: string;
  searchText: string;
  searchedColumn: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearchedColumn: React.Dispatch<React.SetStateAction<string>>;
  searchInput: React.RefObject<InputRef>;
}

export const GetColumnSearchProps = <T extends object>({
  dataIndex,
  indexName,
  searchText,
  searchedColumn,
  setSearchText,
  setSearchedColumn,
  searchInput
}: ColumnSearchProps<T>) => {

  const handleSearchChange = React.useCallback(
    debounce((value: string) => {
      setSearchText(value);
      setSearchedColumn(String(dataIndex));
    }, 600),
    [dataIndex, setSearchText, setSearchedColumn]
  );

  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${indexName}`}
          value={selectedKeys[0] as string}
          onChange={(e) => {
            const { value } = e.target;

            setSelectedKeys(value ? [value] : []);
            handleSearchChange(value);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      const recordValue = record[dataIndex];

      if (recordValue === null || recordValue === undefined) {
        return false;
      }

      return recordValue.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select());
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
};
