import React, { useState, useEffect } from 'react';

interface TransitionSectionProps {
    children: React.ReactNode;
    isSectionActive: boolean;
}

const TransitionSection = ({ children, isSectionActive }: TransitionSectionProps) => {
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (isSectionActive) {
            setStyle({
                maxHeight: '100%',
                transform: 'scaleY(1)',
                transition: 'max-height 0.6s ease-in-out, transform 0.6s ease-in-out',
                overflow: 'hidden',
                transformOrigin: 'top',
            });
        } else {
            setStyle({
                maxHeight: '0px',
                transform: 'scaleY(0)',
                transition: 'max-height 0.6s ease-in-out, transform 0.6s ease-in-out',
                overflow: 'hidden',
                transformOrigin: 'top',
            });
        }
    }, [isSectionActive]);

    return (
        <div style={style}>
            {children}
        </div>
    );
};

export default TransitionSection;
