import React from 'react';
import { BrowserRouter, Navigate, Route as PublicRoute, Routes } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { useContextUsers } from '../context/users';
import ForgotPassword from '../pages/auth/forgotPassword';
import ResetPassword from '../pages/auth/resetPassword';
import Setup from '../pages/auth/setup';
import SignUp from '../pages/auth/signUp';
import NotAuthorized from '../pages/notAuthorized';
import Plans from '../pages/plans';
import Referral from '../pages/referral';
import { IRoute, sideMenuRoutes } from './sideMenu';
import { useAuth } from '../context/auth';

import NotFound from '../pages/notFound';
import SignIn from '../pages/auth/signIn';
import { getDataInStorage } from '../utils/storage';
import { DatabaseIcon, Summary } from '../components/Common/Icon';
import AdminPanel from '../pages/adminPanel';
import WeeklySummary from '../pages/weeklyNews';

export const createChildrenRoutes = (initialRoutes: IRoute[]): IRoute[] => {
  const list: IRoute[] = [...initialRoutes];

  function addChildren(route: IRoute, parent?: IRoute): void {
    let newRoute = route;

    if (parent) {
      newRoute = {
        ...route,
        parent,
        bind: {
          ...route.bind,
          path: `${parent.bind.path}${route.bind.path}`,
        },
      };
      list.push(newRoute);
    }

    if (newRoute.children) {
      newRoute.children.forEach((child) => addChildren(child, newRoute));
    }
  }

  list.forEach((route) => addChildren(route, undefined));

  return list;
};

const Route = (): JSX.Element => {
  const { profile } = useContextUsers();
  const { authorized } = useAuth();

  const hasAdmin = sideMenuRoutes.find((item) => item.name === "Admin Panel")

  const keys = Object.keys(profile && profile.permissions ? JSON.parse(profile?.permissions) : {})

  const filteredSideMenuRoutes = profile && profile.type === 'admin' ? sideMenuRoutes : sideMenuRoutes.filter(route =>
    route.isEssential || (route.name && keys.includes(route.name)));

  if (profile && profile?.type === 'admin' && !hasAdmin) {
    sideMenuRoutes.push(
      {
        bind: {
          path: '/admin',
          element: <AdminPanel />,
        },
        privateRoute: true,
        isSubscriptionNeeded: true,
        icon: <Icon component={DatabaseIcon} />,
        name: 'Admin Panel',
      },
      {
        bind: {
          path: '/summary',
          element: <WeeklySummary />,
        },
        privateRoute: true,
        isSubscriptionNeeded: true,
        icon: <Icon component={Summary} />,
        name: 'Weekly summary',
      },
    );
  }

  const routes: IRoute[] = createChildrenRoutes([
    {
      bind: {
        path: '/not-found',
        element: <NotFound />,
      },
      name: 'Not Found',
    },
    {
      bind: {
        path: '/sign-in',
        element: <SignIn />,
      },
      name: 'Sign In',
    },
    {
      bind: {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      name: 'Forgot Password',
    },
    {
      bind: {
        path: '/reset-password/:code',
        element: <ResetPassword />,
      },
      name: 'Reset Password',
    },
    {
      bind: {
        path: '/sign-up',
        element: <SignUp />,
      },
      name: 'Sign Up',
    },
    {
      bind: {
        path: '/setup',
        element: <Setup />,
      },
      privateRoute: true,
      name: 'Setup',
    },
    {
      bind: {
        path: '/plans',
        element: <Plans />,
      },
      privateRoute: true,
      name: 'Plans',
    },
    {
      bind: {
        path: '/referral/:id',
        element: <Referral />,
      },
      privateRoute: false,
      name: 'Referral',
    },
    ...filteredSideMenuRoutes,
  ]);

  const isAuthorized = (route: IRoute) => {
    const isAvailable = !route.isSubscriptionNeeded || (route.isSubscriptionNeeded && profile?.subscriptionId);

    return (
      !route.privateRoute ||
      (route.privateRoute && authorized && isAvailable) ||
      (route.privateRoute && authorized && !profile)
    );
  };

  const hasPLan = getDataInStorage("auth").hasPlan

  const getHomePage = () => {
    if (!authorized) return <PublicRoute path="/" element={<Navigate to="/sign-in" replace />} />;
    if (hasPLan) return <PublicRoute path="/" element={<Navigate to="/home" replace />} />;

    return <PublicRoute path="/" element={<Navigate to="/home" replace />} />;
  };

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <PublicRoute
            key={route.bind.path}
            {...route.bind}
            element={isAuthorized(route) ? route.bind.element : <NotAuthorized />}
          />
        ))}
        {getHomePage()}
        <PublicRoute path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Route;
