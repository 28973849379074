import { AutoComplete, Form, FormItemProps, Popover, Typography } from 'antd';

import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import { NamePath } from 'rc-field-form/lib/interface';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { IOption } from '../../../types/reports';
import { toFixed } from '../../../utils/text';
import styles from './index.module.less';
import { useContextReports } from '../../../context/reports';

interface ICustomInput {
  identifier?: NamePath;
  prefix?: string;
  suffix?: string;
  value?: string | number;
  additionalText?: {
    text: string;
    style?: CSSProperties;
  };
  onChange?: (value: string) => void;
  onSelect?: (value: string) => void;
  bordered?: boolean;
  disabled?: boolean;
  formItemProps?: FormItemProps;
  inputClassName?: string;
  popoverContent?: React.ReactNode;
  options?: IOption[];
  activeOption?: number;
  rowStyles?: CSSProperties;
  onFocus?: () => void;
  onBlur?: (value: string | number | readonly string[] | undefined) => void;
  placeholder?: string;
  tabIndex?: number;
}

const CustomInput = ({
  identifier,
  prefix,
  suffix,
  value,
  additionalText,
  onChange,
  onSelect,
  bordered,
  disabled,
  formItemProps,
  inputClassName,
  options,
  popoverContent,
  rowStyles,
  onFocus,
  onBlur,
  placeholder,
  tabIndex,
}: ICustomInput) => {
  const form = useFormInstance();
  const formItemValue = useWatch(identifier || formItemProps?.name || '', form);
  const [inputValue, setInputValue] = useState<string | number>();
  const [isPopoverOpened, setIsPopoverOpened] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { handleLoansUpdate } = useContextReports()

  useEffect(() => {
    const preparedValue = !formItemValue || formItemValue === '0' ? undefined : formItemValue;

    setInputValue(prefix === '$' && preparedValue ? toFixed(preparedValue, 0) : preparedValue);
  }, [formItemValue]);

  useEffect(() => {
    if (!value || !identifier) return;

    form.setFieldValue(identifier, value);
  }, [value]);

  const handleChange = (newValue: string) => {
    if (!identifier) return;

    form.setFieldValue(identifier, newValue);
    onChange?.(newValue);
    handleLoansUpdate?.()
  };

  if (popoverContent) {
    return (
      <Popover
        ref={ref}
        showArrow={false}
        trigger="click"
        open={isPopoverOpened}
        onOpenChange={setIsPopoverOpened}
        placement="bottomLeft"
        overlayClassName="popover-select"
        content={
          <div
            onBlur={() => {
              setIsPopoverOpened(false);
            }}
          >
            {popoverContent}
          </div>
        }
      >
        <div className={inputClassName !== 'bg-white' ? styles.inputWrapper : ''}>
          <Form.Item
            {...formItemProps}
            className={clsx('custom-input-form-item', 'label-gray', formItemProps?.className, {
              [styles.bordered]: bordered,
            })}
            style={rowStyles}
          >
            <NumericFormat
              style={rowStyles}
              thousandSeparator
              disabled={disabled}
              onChange={(e) => {
                handleChange(e.target.value.replace(/[^0-9.]/g, ''));
              }}
              className={inputClassName}
              onFocus={() => {
                onFocus?.();
                setIsPopoverOpened(true);
              }}
              onBlur={() => onBlur?.(inputValue)}
              placeholder={placeholder}
              value={inputValue}
              prefix={prefix}
              suffix={suffix}
              tabIndex={tabIndex}
            />
          </Form.Item>
        </div>
      </Popover>
    );
  }

  if (options) {
    return (
      <div className={inputClassName !== 'bg-white' ? styles.inputWrapper : ''}>
        <Form.Item
          {...formItemProps}
          className={clsx('custom-input-form-item flex-row gap-4', 'label-gray', formItemProps?.className, {
            [styles.bordered]: bordered,
          })}
          style={rowStyles}
        >
          <AutoComplete
            className="autocomplete-borderless"
            options={options.map((option) => ({
              label: option.label,
              value: option.value,
            }))}
            onSelect={(selectValue) => {
              handleChange(selectValue);
              onSelect?.(selectValue);
            }}
            tabIndex={tabIndex}
          >
            <NumericFormat
              style={rowStyles}
              thousandSeparator
              disabled={disabled}
              onChange={(e) => {
                handleChange(e.target.value.replace(/[^0-9.]/g, ''));
              }}
              className={inputClassName}
              onFocus={() => onFocus?.()}
              onBlur={() => onBlur?.(inputValue)}
              placeholder={placeholder}
              value={inputValue}
              prefix={prefix}
              suffix={suffix}
            />
          </AutoComplete>
        </Form.Item>
      </div>
    );
  }

  return (
    <div className={inputClassName !== 'bg-white' ? styles.inputWrapper : ''}>
      <Form.Item
        {...formItemProps}
        className={clsx('custom-input-form-item', 'label-gray', formItemProps?.className, {
          [styles.bordered]: bordered,
        })}
      >
        <NumericFormat
          style={{ ...rowStyles }}
          thousandSeparator
          disabled={disabled}
          displayType={additionalText ? 'text' : 'input'}
          onChange={(e) => {
            handleChange(e.target.value.replace(/[^0-9.]/g, ''));
          }}
          className={inputClassName}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.(inputValue)}
          placeholder={placeholder}
          value={additionalText && !inputValue ? 0 : inputValue}
          prefix={prefix}
          suffix={suffix}
          tabIndex={tabIndex}
        />
        {additionalText && (
          <Typography.Text className={clsx(styles.label)} style={additionalText.style}>
            {additionalText.text}
          </Typography.Text>
        )}
      </Form.Item>
    </div>
  );
};

CustomInput.defaultProps = {
  identifier: undefined,
  prefix: undefined,
  suffix: undefined,
  value: undefined,
  additionalText: undefined,
  onChange: undefined,
  onSelect: undefined,
  bordered: false,
  disabled: false,
  formItemProps: undefined,
  inputClassName: undefined,
  showArrow: false,
  popoverContent: undefined,
  options: undefined,
  activeOption: undefined,
  onOptionClick: undefined,
  rowStyles: undefined,
  onBlur: undefined,
  onFocus: undefined,
  placeholder: undefined,
  tabIndex: undefined,
};

export default CustomInput;
