/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Button, Input } from 'antd';
import { useContextReports } from '../../../../../context/reports';
import { useContextUsers } from '../../../../../context/users';
import { useReportId, useReportVideoUpdate } from '../../../../../hooks/reports';
import ReportSection from '../Section';
import './video.less'

interface IVideoSection {
  provided?: DraggableProvided;
}

export const simplifyEmbedCode = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const iframe = doc.querySelector('iframe');

  if (!iframe) return '';
  iframe.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;');
  iframe.setAttribute('frameBorder', '0');
  iframe.setAttribute('allowFullScreen', '');

  iframe.removeAttribute('webkitallowfullscreen');
  iframe.removeAttribute('mozallowfullscreen');

  const div = doc.createElement('div');

  div.setAttribute('style', 'position: relative; padding-bottom: 56.25%; height: 0;');
  div.appendChild(iframe);

  return div.outerHTML;
}

const VideoWalk = ({ provided }: IVideoSection) => {
  const name = 'vidoeWalk';

  const reportById = useReportId()
  const { profile } = useContextUsers();
  const { report, isBorrower, reportId } = useContextReports();
  const [editVideo, setEditVideo] = useState<string>();
  const [edit, setEdit] = useState<boolean>(false);
  const updateReport = useReportVideoUpdate()
  const [toggleUpdate, setToggleUpdate] = useState<boolean>(false)

  useEffect(() => {
    if (isBorrower && !report?.video) return;

    const content = report?.video;

    if (content && !editVideo) {

      setEditVideo(content);

    }
  }, [profile, report, toggleUpdate]);

  const handelUpdate = () => {

    if (!editVideo) {
      setEdit(false)

      return
    }

    setEdit(!edit)
    updateReport.fetch(
      { video: editVideo },
      report?.id
    ).then(() => {
      setEdit(false)

      reportById.fetch(undefined, reportId).then(() => setToggleUpdate(!toggleUpdate)
      )
    });
  }

  const handleCancel = () => {
    setEditVideo(editVideo);
    setEdit(false);
  };

  if(isBorrower && !editVideo){
    return null
  }

  return (
    <ReportSection
      name={name}
      title={isBorrower ? 'Click Below to Watch Your Video' : "Video"}
      description={isBorrower ? null : `Please enter your video embed code below. 
      External video links will not work in this section.  You must use an embed code.
     ` }
      provided={provided}>
      {isBorrower ? (
        <div className='videoContainer' >
          <div dangerouslySetInnerHTML={{ __html: simplifyEmbedCode(editVideo || '') }} />
        </div>
      ) : (
        <div className='container'>
          <div className='editContainer'>
            {edit ? (
              <div>
                <Input.TextArea className='input'
                  rows={6} value={editVideo} onChange={(e) => setEditVideo(e.target.value)} />
                <div className='buttonContainer'>
                  <Button type='primary' className='button' onClick={handelUpdate}>Save</Button>
                  <Button type='primary' className='button' onClick={handleCancel}>Cancel</Button>
                </div>
              </div>
            ) : (
              <Button type='primary'
                className='button' onClick={() => setEdit(true)}>{editVideo ? 'Edit Video' : 'Add Video'}</Button>
            )}
          </div>
          <div className='videoContainer'>
            <div dangerouslySetInnerHTML={{ __html: simplifyEmbedCode(editVideo || '') }} />
          </div>
        </div>
      )}
    </ReportSection>
  );
};

VideoWalk.defaultProps = {
  provided: undefined,
};


export default VideoWalk;
