import { useEffect } from 'react';
import Intercom from 'react-intercom';
import { useContextUsers } from './context/users';

const IntercomWidget = () => {

    const { profile, getProfile } = useContextUsers()

    useEffect(() => {
        if (profile || !getProfile) return;

        if(!profile) return
        
        getProfile();
    }, [profile]);

    return (
        profile && <Intercom
            appID={process.env.REACT_APP_INTERCOM_APP_ID as string}
            name={`${profile?.firstName || ''} ${profile?.lastName || ''}`}
            user_id={profile?.id}
            email={profile?.email}
            created_at={Date.now()}
        />
    )
};

export default IntercomWidget;
