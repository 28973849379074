import Layout from '../../../../components/Layout/Main';
import Content from '../../../../components/Pages/Home/Activities/details';
import { displayName } from '../../../../config';

const ActivitiesByReport = (): JSX.Element => {
    document.title = `${displayName}: Activities by report`;

    return (
        <Layout>
            <Content />
        </Layout>
    );
};

export default ActivitiesByReport;
