import Title from 'antd/lib/typography/Title';
import Layout from '../../components/Layout/Main';
import { displayName } from '../../config';
import UserTable from './userTable';

const AdminPanel = () => {
  document.title = `${displayName}: Admin Dashboard`;

  return (
    <Layout >
      <Title style={{ padding: "12px" }}>Dashboard</Title>
      <UserTable />
    </Layout>
  );
};

export default AdminPanel;
