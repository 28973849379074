import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';
import { useReportCreate, useReportTrackActivities } from '../../../hooks/reports';
import { JsonResult } from '../../../types';
import Loading from '../../../components/Common/Loading';

const ReportCreate = (): JSX.Element => {
  document.title = `${displayName}: Report Create`;
  const reportCreate = useReportCreate();
  const reportTrackActivities = useReportTrackActivities();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    const loanColor: JsonResult = {};

    reportCreate.fetch({ name: '', status: 'draft', loanColor }).then((res) => {
      if (!res?.data.id) return;

      reportTrackActivities.fetch({ id: res.data.id, type: 'created' }).then(() => {
        navigate(`/reports/${res.data.id}/edit`)
      });
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return (
    <Layout>
      <Loading absolute visible={loading} />
      <ReportsProvider value={{ isBorrower: false }}>
        <Content isLoading={loading} />
      </ReportsProvider>
    </Layout>
  );
};

export default ReportCreate;
