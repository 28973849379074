import { FetchResponse } from '../types';
import {
  FetchGet,
  useFetchGet,
} from './fetch';

type ApiResponse = {
  tableData: TableDataItem[];
  page: number;
  limit: number;
  totalPages: number;
  totalUsers: number | undefined;
}

export type TableDataItem = {
  id: string;
  email: string;
  name: string | null;
  phoneNumber: string | null;
  nmlsId: string;
  isActive: boolean;
  reportNumber: number;
  plan: string;
}

export interface ITableParams {
  page?: number;
  pageSize?: number;
  sortBy?: string | null;
  sortDirection?: string | null;
  searchText: string | null;
  searchedColumn: string | null;
}

export const useGetTableData = (): FetchGet<FetchResponse<ApiResponse>, ITableParams> =>
  useFetchGet('admin', { autoStart: false, startStateLoading: true, multiple: 'admin' });