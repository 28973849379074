import { FetchResponse, FetchSuccess } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { IReport, IReportsGetParams } from './reports';

export interface IUserCompany {
  name?: string;
  website?: string;
  nmls?: string;
  address?: string;
}

export interface IUserUpdateParams {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  company?: IUserCompany;
}

export interface IProfile {
  address: string | null;
  avatar: string | null;
  balance: number;
  companyName: string | null;
  customerId: string | null;
  email: string;
  firstName: string | null;
  id: number;
  isPremium: boolean;
  lastName: string | null;
  logo: string | null;
  nmls: string | null;
  nmlsId: string | null;
  phoneNumber: string | null;
  planId: string | null;
  subscriptionId: string | null;
  userId: number | null;
  videoUrl: string | null;
  websiteLink: string | null;
  reference: string | null;
  closingText: string | null;
  settings_logo: string | null;
  welcome_message: string | null;
  type: string | null;
  insurance?: boolean;
  permissions?: string;
}

export interface IProfileUpdateParams {
  address?: string | null;
  companyName?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  nmls?: string | null;
  phoneNumber?: string | null;
  videoUrl?: string | null;
  websiteLink?: string | null;
  closingText?: string | null;
  settings_logo?: string | null;
  welcome_message?: string | null;
}

export interface ISettingItem {
  active: boolean;
  id: number;
  order: number;
  text: string;
  type: string;
  userId?: number;
  name: string;
}

export interface IUserSettings {
  generalNotification: ISettingItem[];
  marketNotification: ISettingItem[];
  reportView: ISettingItem[];
  tableView: ISettingItem[];
}

export interface IUserSettingsParams {
  generalNotification?: ISettingItem[];
  marketNotification?: ISettingItem[];
  reportView?: ISettingItem[];
  tableView?: ISettingItem[];
}

export interface IReferralParams {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  comment?: string;
  id: string;
}

export interface IReferral {
  id: string;
  reportId: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
}

export interface IReferralItem {
  Report: IReport;
  comment: string;
  createdAt: string;
  email: string;
  fullName: string;
  id: string | number;
  phoneNumber: string;
  reportId: string | number;
  updatedAt: string;
  status: 'new' | 'contacted';
}

export interface IReferrals {
  referrals: IReferralItem[];
}

export const useUserId = (): FetchGetId<FetchResponse<IProfile>> =>
  useFetchGetId('users/user', '', { autoStart: false });

export const useLoanOfficerId = (): FetchGetId<FetchResponse<IProfile>> =>
  useFetchGetId('/loans/officier', '', { autoStart: false });

export const useUserUpdate = (): FetchUpdate<FetchResponse<IProfile>, DefaultFetchError, IUserUpdateParams> =>
  useFetchUpdate('users');

export const useProfileId = (): FetchGetId<FetchResponse<IProfile>> =>
  useFetchGetId('users/profile', '', { autoStart: false, multiple: 'profile' });

export const useProfileUpdate = (): FetchUpdate<IProfile, DefaultFetchError, IProfileUpdateParams> =>
  useFetchUpdate('users/profile');

export const useUserFileUpload = (): FetchCreate<FetchResponse<string>, DefaultFetchError, FormData> =>
  useFetchCreate('users/file-upload');

export const useUserSettings = (): FetchGet<FetchResponse<IUserSettings>> =>
  useFetchGet('settings', { autoStart: false });

export const useUserSettingsUpdate = (): FetchUpdate<FetchResponse<string>, DefaultFetchError, IUserSettingsParams> =>
  useFetchUpdate('settings');

export function useReferrals<D = FetchResponse<IReferrals>, DD = D>(
  decorateData?: (data: D) => DD
): FetchGet<DD, IReportsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>('users/referral', { decorateData, autoStart: false });
}

export const useCreateReferral = (): FetchCreate<FetchResponse<IReferral>, DefaultFetchError, IReferralParams> =>
  useFetchCreate('users/referral');

export const useUserDelete = (): FetchDelete<FetchSuccess> => useFetchDelete('users');